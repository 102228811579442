










































































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      isNew: true,
      form: {
        created_at: new Date(),
        products: [],
        condition: 'WA'
      },
      formProduct: {},
      tab: 1,
      rules: {
        created_at: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
        delivery_date: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
        lot: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("products", ["productsList"]),
    ...mapGetters("orders", ["versionsList", "errorList"]),
    ...mapGetters("orders", ["versionsList", "storagesList"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    errorList(value) {
      if (value.status !== null && value.status !== undefined) {
        setTimeout(() => {
          this.$store.dispatch("toast", {
            message: "Stato: campo non valido",
            type: "error",
          })
        }, 1000);
      }
      if (value.status_merch !== null && value.status_merch !== undefined) {
        setTimeout(() => {
          this.$store.dispatch("toast", {
            message: "Verifica merci e trasporti: campo non valido",
            type: "error",
          })
        }, 1000);
      }
      if (value.status_condition !== null && value.status_condition !== undefined) {
        setTimeout(() => {
          this.$store.dispatch("toast", {
            message: "Verifica condizioni: campo non valido",
            type: "error",
          })
        }, 1000);
      }
      if (value.status_transport !== null && value.status_transport !== undefined) {
        setTimeout(() => {
          this.$store.dispatch("toast", {
            message: "Verifica trasporto: campo non valido",
            type: "error",
          })
        }, 1000);
      }
      if (value.status_doc !== null && value.status_doc !== undefined) {
        setTimeout(() => {
          this.$store.dispatch("toast", {
            message: "Verifica etichette, doc: campo non valido",
            type: "error",
          })
        }, 1000);
      }
    },
  },
  created() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("orders/findOrder", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/acquisti";
          } else {
            (
              this as Vue & {
                getOrder: (data: any) => void;
              }
            ).getOrder(result.data);
          }
        });
      this.$data.isNew = false;
    }
    this.$store.dispatch("products/getProducts");
    this.$store.dispatch("orders/getStorage");
  },
  methods: {
    getOrder(data: any) {
      this.$set(this.$data, "form", data);
      this.$data.form.products = this.$data.form.products.map((x: any) => {
        return {
          id: x.id,
          product: x.product.id,
          quantity: x.quantity,
        };
      });
      this.$store.dispatch("orders/getVersions", data.id);
    },
    saveNewProduct() {
      if (this.$data.isNew) {
        this.$data.form.products.push({ ...this.$data.formProduct });
        this.$data.formProduct = {};
      } else {
        this.$store
          .dispatch("orders/addOrderProduct", {
            ...this.$data.formProduct,
            order: this.$props.id,
          })
          .then((status) => {
            if (status == 201) {
              this.$store
                .dispatch("orders/findOrder", this.$props.id)
                .then((result) => {
                  (
                    this as Vue & {
                      getOrder: (data: any) => void;
                    }
                  ).getOrder(result.data);
                });
              this.$data.formProduct = {};
            }
          });
      }
    },
    editProduct(row: any) {
      if (!row.id) return;

      this.$store
        .dispatch("orders/editOrderProduct", {
          ...row,
          order: this.$props.id,
        })
        .then((status) => {
          if (status == 200) {
            this.$store
              .dispatch("orders/findOrder", this.$props.id)
              .then((result) => {
                (
                  this as Vue & {
                    getOrder: (data: any) => void;
                  }
                ).getOrder(result.data);
              });
          }
        });
    },
    removeProduct(id: number | undefined, index: number) {
      if (!id) {
        this.$data.form.products.splice(index, 1);
      } else {
        this.$store
          .dispatch("orders/removeOrderProduct", {
            id,
          })
          .then((status) => {
            if (status == 204) {
              this.$store
                .dispatch("orders/findOrder", this.$props.id)
                .then((result) => {
                  (
                    this as Vue & {
                      getOrder: (data: any) => void;
                    }
                  ).getOrder(result.data);
                });
            }
          });
      }
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          this.$data.form.created_at = moment(
            this.$data.form.created_at
          ).format("YYYY-MM-DD");
          this.$data.form.delivery_date = moment(
            this.$data.form.delivery_date
          ).format("YYYY-MM-DD");

          if (this.$data.form.delivery_expectation) {
            this.$data.form.delivery_expectation = moment(
              this.$data.form.delivery_expectation
            ).format("YYYY-MM-DD");
          }

          this.$data.form.ddt_date = moment(this.$data.form.ddt_date).format(
            "YYYY-MM-DD"
          );

          if (this.$data.isNew) {
            this.$store
              .dispatch("orders/createOrder", {
                ...this.$data.form,
              })
              .then(async (id) => {
                if (id > 0) {
                  for (const product of this.$data.form.products) {
                    this.$store.dispatch("orders/addOrderProduct", {
                      ...product,
                      order: id,
                    });
                  }
                  setTimeout(() => {
                    window.location.href = "/acquisti/";
                  }, 1000);
                }
              });
          } else {
            this.$store
              .dispatch("orders/editOrder", {
                ...this.$data.form,
              })
              .then(() => {
                this.$store.dispatch("orders/getVersions", this.$data.form.id);
              });
          }
        }
      });
    },
  },
})
export default class OrdersEdit extends Vue {
  orders?: any;
  inLoading!: boolean;
}
