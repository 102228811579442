import { RouteConfig } from "vue-router";

import Analysis from "@/views/orders/Analysis.vue";
import Monitoring from "@/views/orders/Monitoring.vue";
import YearStats from "@/views/orders/YearStats.vue";
import Orders from "@/views/orders/Orders.vue";
import OrdersEdit from "@/views/orders/OrdersEdit.vue";
import Traceability from "@/views/orders/Traceability.vue";
import TraceabilityEdit from "@/views/orders/TraceabilityEdit.vue";

import denyConcessionaires from "./denyConcessionaires";

const routes: Array<RouteConfig> = [
  {
    path: "/analisi",
    name: "orders-analysis",
    component: Analysis,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/monitoraggio",
    name: "orders-monitoring",
    component: Monitoring,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/vista-annuale",
    name: "orders-stats",
    component: YearStats,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/acquisti",
    name: "orders",
    component: Orders,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/acquisti/:id",
    name: "orders-edit",
    component: OrdersEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
  {
    path: "/tracciabilita",
    name: "orders-traceability",
    component: Traceability,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/tracciabilita/:id",
    name: "orders-traceability-edit",
    component: TraceabilityEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
];

export default routes;
