import store from "@/store";

// eslint-disable-next-line
const denyConcessionaires = async (to: any, from: any, next: any) => {
  await store.dispatch("auth/findMe");
  const me = store.getters["auth/me"];

  if (me.roles.filter((x: any) => x.description == "Concessionario").length) {
    next({ name: "home" });
  } else {
    next();
  }
};

export default denyConcessionaires;
