

















































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      nationalities: ["IT", "FG"],
      orders: [],
      counts: { IT: 0, FG: 0 },
      isOffice: true,
    };
  },
  computed: {
    ...mapGetters("orders", ["count"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    nationalities() {
      (
        this as Vue & {
          updateOrderTable: () => void;
        }
      ).updateOrderTable();
    },
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
        }
      } 
    },
  },
  created() {
    for (const nationality of ["IT", "FG"]) {
      this.$store
        .dispatch("orders/filterOrders", {
          nationality,
        })
        .then((response) => {
          this.$data.counts[nationality] = response.count;
        });
    }
    (
      this as Vue & {
        updateOrderTable: () => void;
      }
    ).updateOrderTable();
  },
  methods: {
    handleAction() {
      window.location.href = "/acquisti/nuovo";
    },
    async updateOrderTable() {
      this.$data.pageNum =
        parseInt((this.$route.query["p"] as string) ?? null) || 1;

      switch (this.$data.nationalities.length) {
        case 0:
          this.$data.orders = [];
          break;
        case 2:
          await this.$store
            .dispatch("orders/getOrders", {
              p: this.$data.pageNum,
            })
            .then((response) => {
              this.$data.orders = response.results;
            });
          break;
        default:
          for (let nationality of this.$data.nationalities) {
            await this.$store
              .dispatch("orders/filterOrders", {
                p: this.$data.pageNum,
                nationality,
              })
              .then((response) => {
                this.$data.orders = response.results;
              });
          }
      }

      setTimeout(() => {
        this.$set(this.$data, "currentPage", this.$data.pageNum);
      }, 1000);
    },
  },
})
export default class Orders extends Vue {
  orders?: any;
  inLoading!: boolean;
}
