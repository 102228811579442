import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface RatesState {
  rates: any;
  count: number;
}

type RatesContext = ActionContext<RatesState, RootState>;

const rates = {
  namespaced: true,
  state: {
    rates: [],
    count: 0,
  },
  getters: {
    ratesList: (state: RatesState): any => {
      return state.rates;
    },
    count: (state: RatesState): number => {
      return state.count;
    },
  },
  mutations: {
    saveRatesList: (state: RatesState, list: any): void => {
      state.rates = list;
    },
    saveRatesCount: (state: RatesState, count: number): void => {
      state.count = count;
    },
  },
  actions: {
    // Get all rates
    async getRates(context: RatesContext) {
      const api = context.rootState.api;

      await fetch(`${api}/rates/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveRatesList", result.results);
        context.commit("saveRatesCount", result.count);
      });
    },
    // Create a rates
    async createRates(context: RatesContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;
      const api = context.rootState.api;
      await fetch(`${api}/rates/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Aliquota salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit a rates
    async editRates(context: RatesContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/rates/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Aliquota salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default rates;
