import Vue from "vue";
import Vuex from "vuex";
import { Message } from "element-ui";
import { RootState } from "./state";

import auth from "./modules/auth";
import profiles from "./modules/profiles";
import concessionaires from "./modules/concessionaires";
import suppliers from "./modules/suppliers";
import machines from "./modules/machines";
import products from "./modules/products";
import orders from "./modules/orders";
import components from "./modules/components";
import registers from "./modules/registers";
import assistances from "./modules/assistances";
import revisions from "./modules/revisions";
import invoices from "./modules/invoices";
import productions from "./modules/productions";
import rates from "./modules/rates";
import paymentmethods from "./modules/paymentmethods";
import contacts from "./modules/contacts";
import catalogs from "./modules/catalogs";

Vue.use(Vuex);

interface ToastInterface {
  // eslint-disable-next-line
  message: any;
  // eslint-disable-next-line
  type: any;
}

type ToastType = ToastInterface;

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_BACKEND_URL,
    loading: false,
  },
  getters: {
    inLoading: (state: RootState): boolean => {
      return state.loading;
    },
  },
  mutations: {
    async changeLoadingStatus(state: RootState, value: boolean) {
      state.loading = value;
    },
  },
  actions: {
    // @typescript-eslint/no-unused-vars
    // eslint-disable-next-line
    async toast({}, data: ToastType) {
      if (typeof data.message == "string") {
        Message({ ...data, showClose: true });
      } else if (typeof data.message == "object") {
        for (const key of Object.keys(data.message)) {
          if (typeof data.message[key] == "string") {
            setTimeout(() => {
              Message({
                type: data.type,
                message: `${key}: ${data.message[key]}`,
                showClose: true,
              });
            }, 200);
          } else {
            for (const message of data.message[key]) {
              setTimeout(() => {
                Message({
                  type: data.type,
                  message: `${key}: ${message}`,
                  showClose: true,
                });
              }, 200);
            }
          }
        }
      }
    },
    // Make request to get the image with authorization
    async getDocument({ commit, rootGetters }, url: string) {
      url = url.replace("http:", "https:");

      commit("changeLoadingStatus", true);
      return fetch(url, {
        headers: {
          Authorization: `Token ${rootGetters["auth/accessToken"]}`,
        },
      })
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((callback) => {
              const reader = new FileReader();
              reader.onload = function () {
                callback(this.result);
              };
              reader.readAsDataURL(blob);
            })
        )
        .finally(() => {
          commit("changeLoadingStatus", false);
        });
    },
  },
  modules: {
    auth,
    profiles,
    concessionaires,
    suppliers,
    machines,
    products,
    orders,
    components,
    registers,
    assistances,
    revisions,
    invoices,
    productions,
    rates,
    paymentmethods,
    contacts,
    catalogs,
  },
});
