

































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {
      viewTable: false,
      machineForm: [],
      componentForm: [],
      isOffice: true,
      requests: [],
      requestsFilter: [],
      viewCatalog: true,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("catalogs", ["catalogsList", "requestList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("catalogs/getRequest");
    this.$store.dispatch("catalogs/getCatalog");
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == "Admin") {
          this.$data.isOffice = true;
          admin = false;
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == "Ufficio Acquisti") {
              this.$data.isOffice = false;
            }
          }
          for (let i of value.roles) {
            if (
              i.description == "Magazzino" ||
              i.description == "Capo produzione"
            ) {
              this.$data.isOffice = true;
            }
          }
        }
      }
    },
    requestList(value) {
      this.$data.requests = { ...value }
    }
  },
  methods: {
    //Archive Catalog
    archiveCatalog(id: number) {
      this.$store.dispatch("catalogs/editCatalog", {
        id: id,
        is_active: false,
      });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getCatalog");
      }, 1000);
    },
    //Unarchive Catalog
    unarchiveCatalog(id: number) {
      this.$store.dispatch("catalogs/editCatalog", { id: id, is_active: true });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getCatalog");
      }, 1000);
    },
    async viewGetCatalog(value: any) {
      if (value === "colonne") {
        this.$data.viewCatalog = true;
      } else {
        this.$data.viewCatalog = false;
      }
    },
    viewTableImpiComp(value: any) {
      this.$data.requestsFilter = []
      this.$data.machineForm = value.machines;
      this.$data.componentForm = value.components;
      for (let i in this.$data.requests) {
        if (this.$data.requests[i].catalog.id === value.id || this.$data.requests[i].note != '') {
          this.$data.requestsFilter.push(this.$data.requests[i])
        }
      }
      this.$data.viewTable = true;
    },
    handleAction() {
      window.location.href = "/magazzino/catalogo/nuovo";
    },
  },
})
export default class Catalogs extends Vue {
  catalogs?: any;
  inLoading!: boolean;
}
