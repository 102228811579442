import store from "@/store";

const authAdminSecretariat = async (to: any, from: any, next: any) => {
  await store.dispatch("auth/findMe");
  const me = store.getters["auth/me"];

  if (
    me.roles &&
    (me.roles.filter((x: any) => x.description == "Admin").length || 
    me.roles.filter((x: any) => x.description == "Segreteria").length)
  ) {
    next();
  } else {
    next({ name: "home" });
  }
};

export default authAdminSecretariat;

