import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ContactState {
  contacts: any;
  count: any;
}

type ContactContext = ActionContext<ContactState, RootState>;

const contacts = {
  namespaced: true,
  state: {
    contacts: [],
    count: 0,
  },
  getters: {
    contactsList: (state: ContactState): any => {
      return state.contacts;
    },
    count: (state: ContactState): number => {
      return state.count;
    },
  },
  mutations: {
    saveContactsList: (state: ContactState, list: any): void => {
      state.contacts = list;
    },
    saveContactsCount: (state: ContactState, count: number): void => {
      state.count = count;
    },
  },
  actions: {
    // Save a contact
    async saveContact(context: ContactContext, payload: any) {
      const api = context.rootState.api;

      await fetch(`${api}/suppliers/contacts/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload.form }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Riga salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    },
    async editContactData(context: ContactContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any;

      const api = context.rootState.api;

      await fetch(`${api}/suppliers/contacts/${payload.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          data = await response.json();
          const res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Fornitore modificato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    async deleteContactData(
      context: ContactContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/suppliers/contacts/${payload.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Riga eliminata",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
      return res_status;
    },
    async addDataToContact(
      context: ContactContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any;

      const api = context.rootState.api;

      await fetch(`${api}/suppliers/contacts/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          data = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Fornitore modificato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Add a contact
    async addContact(context: ContactContext, payload: any): Promise<any> {
      const api = context.rootState.api;
      const result = { status: 400, data: {} };

      await fetch(`${api}/suppliers/contacts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.status = response.status;
          if (result.status == 201) {
            result.data = await response.json();
            context.dispatch(
              "toast",
              {
                message: "Riga salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      return result;
    },
    // Remove contact
    async removeContact(
      context: ContactContext,
      payload: any
    ): Promise<number> {
      const api = context.rootState.api;
      let status = 400;

      await fetch(`${api}/suppliers/contacts/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          status = response.status;
          if (status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Riga eliminata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      return status;
    },
    // Get all contacts
    async getContacts(context: ContactContext) {
      const api = context.rootState.api;

      await fetch(`${api}/suppliers/contacts/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveContactsList", result.results);
        context.commit("saveContactsCount", result.count);
      });
    },
    // Create a contact
    async createContact(context: ContactContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const result = { status: 400, data: {} };

      const api = context.rootState.api;
      await fetch(`${api}/contacts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.data = await response.json();
          result.status = response.status;

          if (result.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Contatto salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: result.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
    // Edit a contact
    async editContact(context: ContactContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const result = { status: 400, data: {} };

      const api = context.rootState.api;
      await fetch(`${api}/suppliers/contacts/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.data = await response.json();
          result.status = response.status;

          if (result.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Contatto salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: result.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
  },
};

export default contacts;
