












import Vue from "vue";

export default Vue.extend({
  name: "BlueBar",
  props: ["disable_search"],
  data() {
    return {
      q: "",
    };
  },
  created() {
    const query = this.$route.query;
    if (query["q"]) this.q = query.q as string;
  },
  methods: {
    goback() {
      window.history.back();
    },
  },
});
