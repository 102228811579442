



























import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          }
        ]
      },
    };
  },
  computed: {
    ...mapGetters("orders", ["ordersList"]),
    ...mapGetters("orders", ["storagesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("orders/getStorage")
  },
  methods: {
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          let action = "orders/createStorage";
          if (this.$data.form.id) {
            action = "orders/editStorage";
          }
          this.$store
            .dispatch(action, {
              ...this.$data.form,
            })
            .then((response) => {
              if (response == 201 || response == 200) {
                this.$store.dispatch("orders/getStorage");
                this.$data.form = {};
              }
            });
        }
      });
    },
    editStorage(storage: any) {
      this.$store.dispatch("orders/editStorage", { ...storage });
    },
  },
})
export default class SettingsStorage extends Vue { }
