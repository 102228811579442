

































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";
import { component } from "vue/types/umd";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      versionId: 0,
      showAllProducts: false,
      form: {},
      rules: {
        cod: [{ required: true, message: "Riempi questo campo" }],
        suppliers: [{ required: true, message: "Riempi questo campo" }],
        price: [{ required: true, message: "Riempi questo campo" }],
        description: [{ required: true, message: "Riempi questo campo" }],
        vat: [{ required: true, message: "Riempi questo campo" }],
        min_stock: [{ required: true, message: "Riempi questo campo" }],
        unit: [{ required: true, message: "Riempi questo campo" }],
      },
      isOffice: true,
    };
  },
  computed: {
    ...mapGetters("components", [
      "componentsList",
      "count",
      "versionsList",
      "categoriesList",
    ]),
    ...mapGetters("suppliers", ["suppliersList"]),
    ...mapGetters("rates", ["ratesList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  async mounted() {
    (
      this as Vue & {
        updateComponentTable: () => void;
      }
    ).updateComponentTable();
    this.$store.dispatch("components/getCategories");
    this.$store.dispatch("rates/getRates");
    this.$store.dispatch("suppliers/getSuppliers", {
      limit: 1000,
    });
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
          for (let i of value.roles) {
            if (i.description == 'Magazzino' || i.description == 'Capo produzione') {
              this.$data.isOffice = true
            }
          }
        }
      } 
    },
  },
  methods: {
    async updateComponentTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("components/getComponents", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("components/getComponents", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate(async (valid: boolean) => {
        if (valid) {
          const f = this.$data.form;

          let action = "components/editComponent";

          if (f.id == 0) {
            delete f.id;
            action = "components/createComponent";
          }
          
          this.$store
            .dispatch(action, {
              ...f,
            })
            .then((response) => {
              if (response.status == 201 || response.status == 200) {
                this.$store.dispatch("components/getComponents");
                this.$data.form = {};
              }
            });
        }
      });
    },
    confComponent(component: any) {
      if (component.category === null || component.category === undefined) {
        this.$data.form = {
          ...component,
          suppliers: component.suppliers.map((x: any) => x.id)
        }
      } else {
        this.$data.form = {
          ...component,
          suppliers: component.suppliers.map((x: any) => x.id),
          category: component.category.id,
        }
      }
    },
    newComponent() {
      this.$data.form = { id: 0 };
    },
    getVersions(id: number) {
      this.$store.dispatch("components/getVersions", id);
      this.$data.versionId = id;
    },
    calcGrossPrice(data: any) {
      if (data.price && data.vat_percentage) {
        return (
          Number(data.price) +
          (Number(data.price) * Number(data.vat_percentage)) / 100
        ).toFixed(2);
      }
      return 0;
    },
    stateComponent(value: any) {
      for (let i in value.suppliers) {
        if(!Number.isInteger(value.suppliers[i])){
          value.suppliers[i] = value.suppliers[i].id
        }
      }
      if (value.category !== null) {
        value.category = value.category.id
      } else if (value.category === undefined) {
        value.category = null
      }
      this.$store
        .dispatch("components/editComponent", {
          ...value,
        })
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            this.$store.dispatch("components/getComponents");
          }
        });
    }
  },
})
export default class Components extends Vue {
  components?: any;
  count?: number;
  inLoading!: boolean;
}
