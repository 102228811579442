






















































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return { year: 0, category: null, machine: null };
  },
  computed: {
    ...mapGetters("orders", ["statsTable"]),
    ...mapGetters("components", ["categoriesList"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$data.year = new Date().getFullYear();
    this.$store.dispatch("orders/getStats", { year: this.$data.year });
    this.$store.dispatch("components/getCategories");
    this.$store.dispatch("machines/getMachines");
  },
  watch: {
    year() {
      (
        this as Vue & {
          getStats: () => void;
        }
      ).getStats();
    },
    category() {
      (
        this as Vue & {
          getStats: () => void;
        }
      ).getStats();
    },
    machine() {
      (
        this as Vue & {
          getStats: () => void;
        }
      ).getStats();
    },
  },
  methods: {
    getStats() {
      const [year, category, machine] = [
        this.$data.year,
        this.$data.category,
        this.$data.machine,
      ];
      this.$store.dispatch("orders/getStats", { year, category, machine });
    },
    getMachines(products: any): any {
      const machines = [];
      for (const product of products) {
        for (const machine of product.machines) {
          machines.push(machine.name);
        }
      }

      return [...new Set(machines)].join(", ");
    },
  },
})
export default class YearStats extends Vue {
  inLoading!: boolean;
}
