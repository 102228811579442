import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Dashboard from "@/views/Dashboard.vue";
import Concessionaires from "@/views/Concessionaires.vue";
import ConcessionairesEdit from "@/views/ConcessionairesEdit.vue";
import Suppliers from "@/views/Suppliers.vue";
import SuppliersEdit from "@/views/SuppliersEdit.vue";
import Invoices from "@/views/Invoices.vue";
import InvoicesEdit from "@/views/InvoicesEdit.vue";
import Page404 from "@/views/Page404.vue";
import Secretariat_order from "@/views/Secretariat.vue";
import SecretariatOrdersEdit from "@/views/SecretariatOrdersEdit.vue";
import SecretariatCatalog from "@/views/SecretariatCatalogs.vue";

import loginBeforeEnter from "./loginBeforeEnter";
import denyConcessionaires from "./denyConcessionaires";

import settingsRoutes from "./settings";
import ordersRoutes from "./orders";
import storesRoutes from "./stores";
import concessionairesRoutes from "./concessionaires";
import productionsRoutes from "./production";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "*", name: "page404", component: Page404 },
  { path: "/", name: "home", component: HomeView },
  { path: "/forgot", name: "forgot-password", component: ForgotPassword },
  {
    path: "/password-reset/confirm/:uid/:token/",
    name: "password-reset-confirm",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/app",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/concessionarie",
    name: "concessionaires",
    component: Concessionaires,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/concessionarie/:id",
    name: "concessionaires-edit",
    component: ConcessionairesEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
  {
    path: "/fornitori",
    name: "suppliers",
    component: Suppliers,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/fornitori/:id",
    name: "suppliers-edit",
    component: SuppliersEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
  {
    path: "/amministrazione",
    name: "invoices",
    component: Invoices,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/amministrazione/:id",
    name: "invoices-edit",
    component: InvoicesEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
  {
    path: "/segreteria",
    name: "secretariat",
    component: Secretariat_order,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/segreteria/commessa/:id",
    name: "secretariat-edit",
    component: SecretariatOrdersEdit,
    beforeEnter: loginBeforeEnter,
    props: true,
  },
  {
    path: "/catalogo",
    name: "secretariat-catalog",
    component: SecretariatCatalog,
    beforeEnter: loginBeforeEnter,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
    .concat(concessionairesRoutes)
    .concat(settingsRoutes)
    .concat(ordersRoutes)
    .concat(storesRoutes)
    .concat(productionsRoutes)
    .concat(productionsRoutes)
});

export default router;
