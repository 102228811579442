<template lang="pug">
  el-form.flex(ref="form" :model="form" :rules="rules" v-loading="inLoading")
    section
      el-form-item(label="Email" prop="email" required)
        el-input(v-model="form.email")
      el-form-item(label="Password" prop="password" required)
        el-input(v-model="form.password" type="password" :show-password="true")
        a(href="/forgot") dimenticata?

    section.right
      el-button(type="primary" @click="submitForm" round) Accedi
      el-button(type="info" @click="submitForm('Concessionario')" round) Accedi (concessionario)
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      rules: {
        email: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
      },
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
  },
  methods: {
    submitForm(role) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }

        this.$store
          .dispatch("auth/login", { ...this.form, role })
          .then((res) => {
            if (res == 200) {
              window.location.href = "/app";
            }
          });
      });
    },
  },
});
</script>
