



























































import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      form: {},
      rules: {
        label: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("components", ["categoriesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("components/getCategories");
  },
  methods: {
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          let action = "components/createCategory";
          if (this.$data.form.id) {
            action = "components/editCategory";
          }
          if (this.$data.form.color && this.$data.form.color[0] == "#") {
            this.$data.form.color = this.$data.form.color.substr(1);
          }
          this.$store
            .dispatch(action, {
              ...this.$data.form,
            })
            .then((response) => {
              if (response == 201 || response == 200) {
                this.$store.dispatch("components/getCategories");
                this.$data.form = {};
              }
            });
        }
      });
    },
    editCategory(category: any) {
      this.$store.dispatch("components/editCategory", { ...category });
    },
    confCategory(category: any) {
      this.$data.form = { ...category, color: "#" + category.color };
    },
  },
})
export default class SettingsCategories extends Vue { }
