import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ProfileState {
  profiles: any;
  roles: any;
}

type ProfileContext = ActionContext<ProfileState, RootState>;

const profiles = {
  namespaced: true,
  state: {
    profiles: [],
    roles: [],
  },
  getters: {
    profilesList: (state: ProfileState): any => {
      return state.profiles;
    },
    rolesList: (state: ProfileState): any => {
      return state.roles;
    },
  },
  mutations: {
    saveProfilesList: (state: ProfileState, list: any): void => {
      state.profiles = list;
    },
    saveRolesList: (state: ProfileState, list: any): void => {
      state.roles = list;
    },
  },
  actions: {
    // Get all profiles
    async getProfiles(context: ProfileContext, data: any): Promise<any> {
      let page = 1;
      let limit = 24;

      const api = context.rootState.api;

      if (data.p) {
        page = data.p;
      }

      if (data.limit) {
        limit = data.limit;
      }

      const offset = (page - 1) * limit;

      const url = `${api}/profiles/?limit=${limit}&offset=${offset}`;
      let result: any;

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveProfilesList", result.results);
      });

      return result.results;
    },
    // Edit a profile avatar
    async editAvatar(context: ProfileContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      const form = new FormData();
      form.append("avatar", payload.avatar);
      await fetch(`${api}/profiles/${payload.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: form,
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Profilo salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit a profile
    async editProfile(context: ProfileContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      delete payload.avatar;
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/profiles/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Profilo salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Find a profile and return it if found. By its `id`
    async findProfile(
      context: ProfileContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/profiles/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get all roles
    async getRoles(context: ProfileContext) {
      const api = context.rootState.api;

      await fetch(`${api}/profiles/roles/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveRolesList", result.results);
      });
    },
    // Change status user
    async statusUser(context: ProfileContext, payload: any): Promise<any> {
      const api = context.rootState.api;

      await fetch(`${api}/users/active/${payload.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-type": "application/json"
        },
        body: JSON.stringify({ is_active: payload.is_active })
      }).then(async response => {
        const result = {
          data: await response.json(),
          status: response.status
        };

        if(result.status != 200) {
          context.dispatch(
            "toast",
            {
              message: result.data,
              type: "error"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifica salvata",
              type: "success"
            },
            { root: true }
          );
        }
      });
    }
  },
};

export default profiles;
