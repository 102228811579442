import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ProductionsState {
  productions: any;
  count: number;
}

type ProductionContext = ActionContext<ProductionsState, RootState>;

const productions = {
  namespaced: true,
  state: {
    productions: [],
    count: 0,
  },
  getters: {
    productionsList: (state: ProductionsState): any => {
      return state.productions;
    },
    count: (state: ProductionsState): number => {
      return state.count;
    },
  },
  mutations: {
    saveProductionsList: (state: ProductionsState, list: any): void => {
      state.productions = list;
    },
    saveProductionsCount: (state: ProductionsState, count: number): void => {
      state.count = count;
    },
  },
  actions: {
    // Get all products
    async getProductions(context: ProductionContext): Promise<any> {
      const api = context.rootState.api;

      let result: any;
      await fetch(`${api}/productions/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveProductionsList", result.results);
        context.commit("saveProductionsCount", result.count);
      });

      return result.results;
    },
    // Get table filter in StoreRequirements
    async filterProductions(context: ProductionContext, f: any): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];

      await fetch(`${api}/productions/needs/?limit=1000`, {
        method: "POST",
        body: JSON.stringify({ ...f }),
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveProductionsList", result);
        context.commit("saveProductionsCount", result.count);
      });

      return result;
    },
  },
};

export default productions;
