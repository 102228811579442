import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface PaymentmethodsState {
  paymentmethods: any;
  count: number;
}

type PaymentmethodsContext = ActionContext<PaymentmethodsState, RootState>;

const paymentmethods = {
  namespaced: true,
  state: {
    paymentmethods: [],
    count: 0,
  },
  getters: {
    paymentmethodsList: (state: PaymentmethodsState): any => {
      return state.paymentmethods;
    },
    count: (state: PaymentmethodsState): number => {
      return state.count;
    },
  },
  mutations: {
    savePaymentmethodsList: (state: PaymentmethodsState, list: any): void => {
      state.paymentmethods = list;
    },
    savePaymentmethodsCount: (
      state: PaymentmethodsState,
      count: number
    ): void => {
      state.count = count;
    },
  },
  actions: {
    // Get all paymentmethods
    async getPaymentmethods(context: PaymentmethodsContext) {
      const api = context.rootState.api;

      await fetch(`${api}/payment-methods/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("savePaymentmethodsList", result.results);
        context.commit("savePaymentmethodsCount", result.count);
      });
    },
    // Create a paymentmethods
    async createPaymentmethods(
      context: PaymentmethodsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/payment-methods/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Metodo di pagamento salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit a paymentmethods
    async editPaymentmethods(
      context: PaymentmethodsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/payment-methods/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Metodo di pagamento salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default paymentmethods;
