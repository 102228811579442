

































































import Vue from "vue";
import moment from "moment";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TraceabilityEdit",
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      order: {},
      ddt_files: [],
      bill_files: [],
      fileShowed: "",
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.findOrder();
  },
  methods: {
    showFile(source: string) {
      this.$data.fileShowed = source;
    },
    findOrder() {
      this.$store
        .dispatch("orders/findOrder", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/tracciabilita";
          } else {
            this.$data.order = result.data;
            this.$data.ddt_files = result.data.documents.filter(
              (x: any) => x.type == "DDT"
            );
            this.$data.bill_files = result.data.documents.filter(
              (x: any) => x.type == "BIL"
            );
          }
        });
    },
    uploadFile(file: any, type: string) {
      this.$store
        .dispatch("orders/uploadDocument", {
          id: this.$data.order.id,
          file,
          type,
        })
        .then((status: number) => {
          if (status == 201) this.findOrder();
        });
    },
    uploadDDT(file: any) {
      this.uploadFile(file.raw, "DDT");
    },
    uploadBILL(file: any) {
      this.uploadFile(file.raw, "BIL");
    },
    deleteFile(id: number) {
      if (confirm("Sei sicuro?")) {
        this.$store
          .dispatch("orders/deleteDocument", id)
          .then((status: number) => {
            if (status == 204) this.findOrder();
          });
      }
    },
  },
});
