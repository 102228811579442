import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface MachineState {
  machines: any;
  categories: any;
  count: number;
  countCategories: number;
  versions: any;
}

type MachineContext = ActionContext<MachineState, RootState>;

const machines = {
  namespaced: true,
  state: {
    machines: [],
    categories: [],
    count: 0,
    countCategories: 0,
  },
  getters: {
    machinesList: (state: MachineState): any => {
      return state.machines;
    },
    machineCategoriesList: (state: MachineState): any => {
      return state.categories;
    },
    count: (state: MachineState): number => {
      return state.count;
    },
    countCategories: (state: MachineState): number => {
      return state.countCategories;
    },
  },
  mutations: {
    saveMachinesList: (state: MachineState, list: any): void => {
      state.machines = list;
    },
    saveMachinesCount: (state: MachineState, count: number): void => {
      state.count = count;
    },
    saveMachineCategoriesList: (state: MachineState, list: any): void => {
      state.categories = list;
    },
    saveMachineCategoriesCount: (state: MachineState, count: number): void => {
      state.countCategories = count;
    },
  },
  actions: {
    // Align machine children products
    async childAlignment(context: MachineContext, payload: any) {
      const api = context.rootState.api;

      await fetch(`${api}/machines/${payload.id}/align/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ children: payload.children }),
      })
        .then(async (response) => {
          if (response.status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Allineamento avvenuto con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    },
    // Save a product or a machine of a machine
    async saveProductOrMachine(context: MachineContext, payload: any) {
      const api = context.rootState.api;

      await fetch(`${api}/machines/${payload.type}/${payload.form.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload.form }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Riga salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    },
    // Add a product or a machine from a machine
    async addProductOrMachine(
      context: MachineContext,
      payload: any
    ): Promise<any> {
      const api = context.rootState.api;
      const result = { status: 400, data: {} };

      await fetch(`${api}/machines/${payload.type}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.status = response.status;
          if (result.status == 201) {
            result.data = await response.json();
            context.dispatch(
              "toast",
              {
                message: "Riga salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      return result;
    },
    // Remove a product or a machine from a machine
    async removeProductOrMachine(
      context: MachineContext,
      payload: any
    ): Promise<number> {
      const api = context.rootState.api;
      let status = 400;

      await fetch(`${api}/machines/${payload.type}/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          status = response.status;
          if (status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Riga eliminata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      return status;
    },
    // Get all machines
    async getMachines(context: MachineContext) {
      const api = context.rootState.api;

      await fetch(`${api}/machines/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveMachinesList", result.results);
        context.commit("saveMachinesCount", result.count);
      });
    },
    // Get all machines categoies
    async getMachineCategories(context: MachineContext) {
      const api = context.rootState.api;

      await fetch(`${api}/machines/categories/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveMachineCategoriesList", result.results);
        context.commit("saveMachineCategoriesCount", result.count);
      });
    },
    // Create a machine category
    async createMachineCategory(
      context: MachineContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const res = {
        data: null,
        status: 500,
      };

      const api = context.rootState.api;
      await fetch(`${api}/machines/categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res.status = response.status;
          res.data = data;

          if (res.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Categoria salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Create a machine
    async createMachine(context: MachineContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const result = { status: 400, data: {} };

      const api = context.rootState.api;
      await fetch(`${api}/machines/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.data = await response.json();
          result.status = response.status;

          if (result.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Distinta salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: result.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
    // Edit a machine
    async editMachine(context: MachineContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const result = { status: 400, data: {} };

      const api = context.rootState.api;
      await fetch(`${api}/machines/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          result.data = await response.json();
          result.status = response.status;

          if (result.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Distinta salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: result.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
    async editMachineCategory(
      context: MachineContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/machines/categories/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Categoria salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Search name
    async search(context: MachineContext, q: string): Promise<any> {
      const api = context.rootState.api;
      let result;
      await fetch(`${api}/machines/search/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ q }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            const data = await response.json();
            result = data.results;
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      return result;
    },
  },
};

export default machines;
