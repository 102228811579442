<template lang="pug">
  el-dialog(
    title="File"
    :visible="true"
    center
    @close="$emit('hide', '')"
  )
    figure(
      v-loading="inLoading"
      v-if="source.endsWith('jpg') || source.endsWith('jpeg') || source.endsWith('png')"
    )
      img(:src="document" style="max-width: 100%" alt="Document")
    iframe(v-else-if="source.endsWith('pdf')" :src="document" width="100%" height="500")
    el-button(v-else @click="downloadFile")
      | Scarica file
</template>

<script>
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "FileBox",
  props: ["source"],
  data() {
    return {
      document: "",
    };
  },
  watch: {
    source: function (value) {
      this.getDocument(value).then((response) => {
        this.document = response;
      });
    },
  },
  created() {
    this.getDocument(this.$props.source).then((response) => {
      this.document = response;
    });
  },
  computed: {
    ...mapGetters(["inLoading"]),
  },
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.download = this.source.split("/").at(-1);
      link.href = this.document;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    ...mapActions(["getDocument"]),
  },
});
</script>
