














































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      fileShowed: "",
    };
  },
  computed: {
    ...mapGetters("orders", ["ordersList", "count"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    (
      this as Vue & {
        updateOrderTable: () => void;
      }
    ).updateOrderTable();
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
        }
      } 
    },
  },
  methods: {
    async updateOrderTable() {
      this.$data.pageNum =
        parseInt((this.$route.query["p"] as string) ?? null) || 1;
      await this.$store.dispatch("orders/getOrders", {
        p: this.$data.pageNum,
      });
      setTimeout(() => {
        this.$set(this.$data, "currentPage", this.$data.pageNum);
      }, 1000);
    },
    showFile(source: string) {
      this.$data.fileShowed = source;
    },
  },
})
export default class Orders extends Vue {
  orders?: any;
  count?: number;
  inLoading!: boolean;
}
