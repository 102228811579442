
































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable
  },
  data() {
    return {
      note: "",
      machineForm: {},
      componentForm: {},
      viewTable: false,
      catalogList: [],
      requests: [],
      listRequests: [],
      status: '',
      statusList: [
        {
          statusFilter: 'SE',
          label: "Inviato",
        },
        {
          statusFilter: 'AC',
          label: "Accettato",
        },
        {
          statusFilter: 'CO',
          label: "Concluso",
        },
        {
          statusFilter: 'SU',
          label: "Sospeso",
        },
        {
          statusFilter: 'ED',
          label: "Da modificare",
        },
        {
          statusFilter: 'AR',
          label: "Archiviato",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("catalogs", ["catalogsList", "requestList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    requestList(value: any) {
      this.$data.requests = value;
      for (let i in this.$data.requests) {
        if (!isNaN(this.$data.requests[i])) {
          for (let f of this.$data.catalogList) {
            if (this.$data.requests[i].catalog === f.id) {
              this.$data.requests[i].catalog = f
            }
          }
        }
      }
      for (let i in this.$data.requests) {
        if (
          this.$data.requests[i].machines.length == 0 &&
          this.$data.requests[i].components.length == 0
        ) {
          this.$data.requests[i].edit = true;
        } else {
          if (this.$data.requests[i].status == "SE") {
            for (let f of this.$data.requests[i].catalog.components) {
              for (let q of this.$data.requests[i].components) {
                if (f.component.description === q.component.description) {
                  if (f.quantity < q.quantity) {
                    this.$data.requests[i].edit = true;
                  }
                  break;
                }
              }
            }
            for (let f of this.$data.requests[i].catalog.machines) {
              for (let q of this.$data.requests[i].machines) {
                if (f.machine.name === q.machine.name) {
                  if (f.quantity < q.quantity) {
                    this.$data.requests[i].edit = true;
                  }
                  break;
                }
              }
            }
          }
        }
      }
      this.$data.listRequests = this.$data.requests
    },
  },
  created() {
    this.$store.dispatch("catalogs/getCatalog").then(() => {
      this.$data.catalogList = this.$store.getters["catalogs/catalogsList"].map(
        (x: any) => {
          if (x.is_active) {
            return { x };
          }
        }
      );
    });
    this.$store.dispatch("catalogs/getRequest");
  },
  methods: {
    filterStatus() {
      this.$data.requests = this.$data.listRequests
      let filter = []
      if (this.$data.status !== '') {
        if (this.$data.status === 'ED') {
          for (let i in this.$data.requests) {
            if (this.$data.requests[i].edit) {
              filter.push(this.$data.requests[i])
            }
          }
        } else {
          if (this.$data.status === 'SE') {
            for (let i in this.$data.requests) {
              if (this.$data.requests[i].status === this.$data.status && this.$data.requests[i].edit !== true) {
                filter.push(this.$data.requests[i])
              }
            }
          } else {
            for (let i in this.$data.requests) {
              if (this.$data.requests[i].status === this.$data.status) {
                filter.push(this.$data.requests[i])
              }
            }
          }
        }
        this.$data.requests = filter
      }
    },
    viewTableImpiComp(value: any) {
      let f_machine = [];
      let f_component = [];
      let machine;
      let component;
      //Machine
      for (let f of value.catalog.machines) {
        machine = {
          availability: f.quantity,
          machine: f.machine.id,
        };
        f_machine.push(machine);
      }
      this.$data.machineForm = value.machines;
      for (let i in this.$data.machineForm) {
        for (let f of f_machine) {
          if (this.$data.machineForm[i].machine.id === f.machine) {
            this.$data.machineForm[i].availability = f.availability;
          }
        }
      }
      //Component
      for (let f of value.catalog.components) {
        component = {
          availability: f.quantity,
          component: f.component.id,
        };
        f_component.push(component);
      }
      this.$data.componentForm = value.components;
      for (let i in this.$data.componentForm) {
        for (let f of f_component) {
          if (this.$data.componentForm[i].component.id === f.component) {
            this.$data.componentForm[i].availability = f.availability;
          }
        }
      }
      this.$data.note = value.note;
      this.$data.viewTable = true;
    },
    archiveRequest(value: any) {
      const payload = { ...value };
      payload.status = "AR";
      payload.catalog = payload.catalog.id;
      this.$store.dispatch("catalogs/editRequests", { ...payload });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getRequest");
      }, 1000);
    },
  },
})
export default class Requests extends Vue {
  inLoading!: boolean;
}
