import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface AuthState {
  user: any;
  token: string | null;
}

type AuthContext = ActionContext<AuthState, RootState>;

const auth = {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem("access_token") || null,
  },
  getters: {
    accessToken: (state: AuthState): string | null => {
      return state.token;
    },
    isLogged: (state: AuthState): boolean => {
      return state.token != null;
    },
    me: (state: AuthState): any => {
      return state.user;
    },
    isConcessionaire: (state: AuthState): boolean => {
      if (state.user) {
        return (
          state.user.roles.filter((x: any) => x.description == "Concessionario")
            .length > 0
        );
      }

      return false;
    },
  },
  mutations: {
    saveAccessToken: (state: AuthState, key: string): void => {
      localStorage.setItem("access_token", key);
      state.token = key;
    },
    logout: (state: AuthState): void => {
      localStorage.removeItem("access_token");
      state.token = null;
    },
    saveUserInfo: (state: AuthState, data: any): void => {
      state.user = data;
    },
  },
  actions: {
    // Make the login using `credentials`. Returns status code.
    async login(context: AuthContext, payload: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;

      const credentials = { email: payload.email, password: payload.password };

      await fetch(`${api}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (data.key) {
            context.commit("saveAccessToken", data.key);
            await context.dispatch("findMe").then(() => {
              const profile = context.rootGetters["auth/me"];
              const roles = profile.roles.map((x: any) => x.description);

              if (payload.role && payload.role == "Concessionario") {
                if (roles.indexOf(payload.role) < 0) {
                  context.dispatch(
                    "toast",
                    {
                      message: "Non hai i permessi per fare questo login",
                      type: "error",
                    },
                    { root: true }
                  );
                  res_status = 400;
                }
              } else {
                if (roles.indexOf("Concessionario") >= 0) {
                  context.dispatch(
                    "toast",
                    {
                      message: "Non hai i permessi per fare questo login",
                      type: "error",
                    },
                    { root: true }
                  );
                  res_status = 400;
                }
              }

              if (res_status != 400) {
                context.dispatch(
                  "toast",
                  {
                    message: "Login effettuato",
                    type: "success",
                  },
                  { root: true }
                );
              } else {
                context.dispatch("logout");
              }
            });
          } else {
            await context.dispatch(
              "toast",
              { message: "Impossibile eseguire il login con le credenziali immesse", type: "error" },
              { root: true }
            );
          }
        })
        .catch(async (e) => {
          res_status = 500;
          await context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Send request to reset password
    async passwordReset(context: AuthContext, email: string) {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;

      await fetch(`${api}/auth/password/reset/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Email con link di recupero inviata",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch(async (e) => {
          res_status = 500;
          await context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Reset password confirm
    async passwordResetConfirm(context: AuthContext, form: any) {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;

      await fetch(`${api}/auth/password/reset/confirm/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...form }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Password cambiata con successo!",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch(async (e) => {
          res_status = 500;
          await context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Change password
    async changePassword(context: AuthContext, form: any) {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;

      await fetch(`${api}/auth/password/change/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...form }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Password cambiata con successo!",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch(async (e) => {
          res_status = 500;
          await context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Search info for current authed user
    async findMe(context: AuthContext): Promise<number> {
      const response = await context.dispatch("profiles/findProfile", "me", {
        root: true,
      });
      context.commit("saveUserInfo", response.data);
      return response.status;
    },
    // Make a request to backend logout to unvalidate access token
    async logout(context: AuthContext) {
      const api = context.rootState.api;
      await fetch(`${api}/auth/logout/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(() => {
        context.commit("logout");
      });
    },
  },
};

export default auth;
