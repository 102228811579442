





















































































import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      childrenToAlign: [],
      dialogChild: 0,
      form: {
        products: [],
        concessionaire: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        concessionaire: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          }
        ],
      },
      registers: [],
      orderedRegisterList: [],
    };
  },
  computed: {
    ...mapGetters("registers", ["registersList"]),
    ...mapGetters("products", ["productsList"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters("concessionaires", ["concessionairesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("registers/getRegisters");
    this.$store.dispatch("products/getProducts");
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("concessionaires/getConcessionaires", {
      limit: 1000,
    });
  },
  methods: {
    save() {
      if (this.$data.form.products.length === 0 && this.$data.form.machines.length === 0) {
        this.$store.dispatch("toast", {
          message: "Seleziona una componente o distinta",
          type: "error",
        });
      } else {
        (
          this.$refs.form as Vue & {
            validate: (cb: (valid: boolean) => void) => void;
          }
        ).validate(async (valid: boolean) => {
          if (valid) {
            const f = this.$data.form;

            let action = "registers/editRegister";

            if (f.id == 0) {
              delete f.id;
              action = "registers/createRegister";
            }

            this.$store
              .dispatch(action, {
                ...f,
              })
              .then((status) => {
                if (status == 201 || status == 200) {
                  this.$store.dispatch("registers/getRegisters");
                }
              });
          }
        });
      }
    },
    editRegister(register: any) {
      this.$store.dispatch("registers/editRegister", { ...register });
    },
    confRegister(register: any) {
      let f = register
      f.concessionaires = f.concessionaire.id
      this.$data.form = {
        ...f,
        products: f.products.map((x: any) => x.id),
        machines: f.machines.map((x: any) => x.id),
      };
    },
    newRegister() {
      this.$data.form = { id: 0 };
    },
    delRegister(registerId) {
      this.$store.dispatch("registers/deleteRegister", registerId).then(() => {
        this.$store.dispatch("registers/getRegisters");
      });
    },
  },
})
export default class SettingsRegisters extends Vue { }
