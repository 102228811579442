



































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters, mapActions } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("orders", ["ordersList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    (
      this as Vue & {
        updateOrderTable: () => void;
      }
    ).updateOrderTable();
  },
  methods: {
    async updateOrderTable() {
      await this.$store.dispatch("orders/filterOrders", { condition: "PR" });
    },
    componentsAreLowerbound(components: any): boolean {
      // `.map()` returns an array of booleans
      // `.every()` checks if all elements into the array above are `true`
      return components
        .map((x: any) => x.quantity <= x.component.quantity)
        .every((x: boolean) => x);
    },
  },
})
export default class StoreOrders extends Vue {
  inLoading!: boolean;
}
