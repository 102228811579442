import { ActionContext } from "vuex";
import { RootState } from "../state";

const _ = require("lodash");

export interface RevisionState {
  versions?: any;
}

type RevisionContext = ActionContext<RevisionState, RootState>;

const clients = {
  namespaced: true,
  actions: {
    // Get versions from a path
    async getVersions(context: RevisionContext, path: string): Promise<any> {
      let result: any;
      await fetch(path, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        if (response.status == 200) {
          result = await response.json();
          result[0].differences = [];

          for (let i = 1; i < result.length; ++i) {
            result[i].differences = [];
            const serialized_data = JSON.parse(result[i].serialized_data)[0];
            result[i].pk = serialized_data.pk;

            const oldObj = JSON.parse(result[i - 1].serialized_data)[0];

            if (oldObj.pk != serialized_data.pk) {
              result[i].differences = [
                {
                  field: Object.keys(serialized_data.fields).join(", "),
                  before: "",
                  after: Object.values(serialized_data.fields).join(", "),
                },
              ];
            } else {
              const diffKeys = _.reduce(
                oldObj.fields,
                (result: any, value: any, key: string) =>
                  _.isEqual(value, serialized_data.fields[key])
                    ? result
                    : result.concat(key),
                []
              );

              for (const key of diffKeys) {
                result[i].differences.push({
                  field: key,
                  before: oldObj.fields[key],
                  after: serialized_data.fields[key],
                });
              }
            }
          }
        }
      });

      return result;
    },
  },
};

export default clients;
