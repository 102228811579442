import store from "@/store";

// eslint-disable-next-line
const authAdmin = async (to: any, from: any, next: any) => {
  await store.dispatch("auth/findMe");
  const me = store.getters["auth/me"];

  if (
    me.roles &&
    me.roles.filter((x: any) => x.description == "Admin").length
  ) {
    next();
  } else {
    next({ name: "home" });
  }
};

export default authAdmin;
