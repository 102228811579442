import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface AssistanceState {
  assistances: any;
  count: number;
  versions: any;
}

type AssistanceContext = ActionContext<AssistanceState, RootState>;

const assistances = {
  namespaced: true,
  state: {
    assistances: [],
    count: 0,
    versions: [],
  },
  getters: {
    assistancesList: (state: AssistanceState): any => {
      return state.assistances;
    },
    count: (state: AssistanceState): number => {
      return state.count;
    },
    versionsList: (state: AssistanceState): any => {
      return state.versions;
    },
  },
  mutations: {
    saveAssistancesList: (state: AssistanceState, list: any): void => {
      state.assistances = list;
    },
    saveAssistancesCount: (state: AssistanceState, count: number): void => {
      state.count = count;
    },
    saveVersions: (state: AssistanceState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // Get all assistances
    async getAssistances(context: AssistanceContext) {
      const api = context.rootState.api;

      await fetch(`${api}/assistances/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveAssistancesList", result.results);
        context.commit("saveAssistancesCount", result.count);
      });
    },
    // Create a assistance
    async createAssistance(
      context: AssistanceContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any = {
        status: 400,
        data: {},
      };

      const api = context.rootState.api;
      await fetch(`${api}/assistances/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          data = {
            status: response.status,
            data: await response.json(),
          };

          if (data.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Assistenza salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Edit a assistance
    async editAssistance(
      context: AssistanceContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any = {
        status: 400,
        data: {},
      };

      const api = context.rootState.api;
      await fetch(`${api}/assistances/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          data = {
            status: response.status,
            data: await response.json(),
          };

          if (data.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Assistenza salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Get versions list from a assistance `id`
    async getVersions(context: AssistanceContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/assistances/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
  },
};

export default assistances;
