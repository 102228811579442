



















































































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      productions: [],
      fileShowed: "",
      filterObj: {},
      isOffice: true,
      page: false,
      order: false,
      pagePath: "",
    };
  },
  computed: {
    ...mapGetters("concessionaires", ["productionsList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    (
      this as Vue & {
        updateProductionTable: () => void;
      }
    ).updateProductionTable();
    if(this.$route.fullPath == "/segreteria" || this.$route.fullPath == "/segreteria/nuovo") {
      this.$data.page = true
      this.$data.pagePath = "/segreteria"
    } else {
      this.$data.page = false
      this.$data.order = true
      this.$data.pagePath = "/commessa"
    }
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
          for (let i of value.roles) {
            if (i.description == 'Segreteria') {
              this.$data.isOffice = true
            }
          }
        }
      }
    },
  },
  methods: {
    handleAction() {
      window.location.href = "/segreteria/commessa/nuovo";
    },
    confirmOrders(production: any) {
      const payload = { ...production };
      if(payload.status != 'WA') {
        payload.status = "PD"
      } else {
        payload.status = "PR"
      }
      delete payload.concessionaire;
      if (this.$data.file) {
        payload.confirmation_document = this.$data.file;
      } else {
        delete payload.confirmation_document;
      }
      payload.path = this.$data.pagePath
      this.$store.dispatch("concessionaires/editProduction", payload);
    },
    getMachinesIds(machines: any) {
      return machines.map((x: any) => x.cods).join(",")
    },
    getRowsCount(data: any) {
      return data
        .map((x: any) => x.quantity)
        .reduce((a: number, b: number) => a + b, 0);
    },
    async updateProductionTable() {
      this.$data.pageNum =
        parseInt((this.$route.query["p"] as string) ?? null) || 1;

      await this.$store.dispatch("concessionaires/getProductions", {
        p: this.$data.pageNum,
      });

      setTimeout(() => {
        this.$set(this.$data, "currentPage", this.$data.pageNum);
      }, 1000);
    },
    showFile(source: string) {
      this.$data.fileShowed = source;
    },
    async filter() {
      let f = { ...this.$data.filterObj };
      if(f.date_from != "" && f.date_to != "" && f.date_from != null && f.date_to != null){
        this.$store.dispatch("concessionaires/filterProductions", f);
      }
      else{
        this.$store.dispatch("concessionaires/getProductions", {
          p: this.$data.pageNum,
        });
      }
    },
  },
})
export default class Secretariat extends Vue {
  productions?: any;
  inLoading!: boolean;
}
