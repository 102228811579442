import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ConcessionaireState {
  concessionaires: any;
  countConcessionaires: number;
  versions: any;
  productions: any;
  productionsCount: number;
}

type ConcessionaireContext = ActionContext<ConcessionaireState, RootState>;

const concessionaires = {
  namespaced: true,
  state: {
    concessionaires: [],
    countConcessionaires: 0,
    versions: [],
    productions: [],
    productionsCount: 0,
  },
  getters: {
    concessionairesList: (state: ConcessionaireState): any => {
      return state.concessionaires;
    },
    countConcessionaires: (state: ConcessionaireState): number => {
      return state.countConcessionaires;
    },
    versionsList: (state: ConcessionaireState): any => {
      return state.versions;
    },
    productionsList: (state: ConcessionaireState): any => {
      return state.productions;
    },
    productionsCount: (state: ConcessionaireState): number => {
      return state.productionsCount;
    },
  },
  mutations: {
    saveConcessionairesList: (state: ConcessionaireState, list: any): void => {
      state.concessionaires = list;
    },
    saveConcessionairesCountConcessionaires: (
      state: ConcessionaireState,
      countConcessionaires: number
    ): void => {
      state.countConcessionaires = countConcessionaires;
    },
    saveVersions: (state: ConcessionaireState, list: any): void => {
      state.versions = list;
    },
    saveProductionsList: (state: ConcessionaireState, data: any): void => {
      state.productions = data.results;
      state.productionsCount = data.count;
    },
  },
  actions: {
    // Manage data
    // params:
    //  `type`: "employees"
    //  `res`: object returned from PATCH/POST/PUT method
    //  `path`: "users" is the path used for adding/removing elements
    //  `data`: it is the list of elements to add/remove
    async manageData(context: ConcessionaireContext, payload: any) {
      const { type, res, path, data } = payload;

      const api = context.rootState.api;

      const dataToDelete = res[type].filter((x: number) => !data.includes(x));
      for (const id of dataToDelete) {
        await fetch(`${api}/concessionaires/${res.id}/${path}/${id}/`, {
          method: "DELETE",
          headers: {
            Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json",
          },
        }).then(async (response) => {
          if (response.status != 204) {
            const msg = await response.json();
            await context.dispatch(
              "toast",
              { message: msg, type: "error" },
              { root: true }
            );
          }
        });
      }

      const dataToAdd = data.filter((x: number) => !res[type].includes(x));
      for (const id of dataToAdd) {
        await fetch(`${api}/concessionaires/${res.id}/${path}/`, {
          method: "POST",
          headers: {
            Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }).then(async (response) => {
          if (response.status != 200) {
            const msg = await response.json();
            await context.dispatch(
              "toast",
              { message: msg, type: "error" },
              { root: true }
            );
          }
        });
      }
    },
    // Get all concessionaires
    async getConcessionaires(context: ConcessionaireContext, data: any) {
      let page = 1;
      let limit = 24;

      const api = context.rootState.api;

      if (data.p) {
        page = data.p;
      }

      if (data.limit) {
        limit = data.limit;
      }

      const offset = (page - 1) * limit;

      const url = `${api}/concessionaires/?limit=${limit}&offset=${offset}`;

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveConcessionairesList", result.results);
        context.commit("saveConcessionairesCountConcessionaires", result.count);
      });
    },
    // Create a concessionaire
    async createConcessionaire(
      context: ConcessionaireContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      await fetch(`${api}/concessionaires/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Concessionaria salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Edit a concessionaire
    async editConcessionaire(
      context: ConcessionaireContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;

      // This is managed by `manageData`
      const employees = payload.employees;
      delete payload.employees;
      await fetch(`${api}/concessionaires/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          data.employees = data.employees.map((x: any) => x.id);
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Concessionaria salvata con successo",
                type: "success",
              },
              { root: true }
            );
            await context.dispatch("manageData", {
              data: employees,
              res: data,
              type: "employees",
              path: "users",
            });
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Find a concessionaire and return it if found. By its `id`
    async findConcessionaire(
      context: ConcessionaireContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/concessionaires/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get versions list from a concessionaire `id`
    async getVersions(context: ConcessionaireContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/concessionaires/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Create an production
    async createProduction(
      context: ConcessionaireContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      const form = new FormData();
      for (const key of Object.keys(payload)) {
        form.append(key, payload[key]);
      }

      await fetch(`${api}/productions/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: form,
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Commessa salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Find an production and return it if found. By its `id`
    async findProduction(
      context: ConcessionaireContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/productions/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Edit an production
    async editProduction(
      context: ConcessionaireContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;
      const path = payload.path;
      delete payload.path;
      const api = context.rootState.api;

      const form = new FormData();
      for (const key of Object.keys(payload)) {
        form.append(key, payload[key]);
      }

      await fetch(`${api}/productions/${payload.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: form,
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            window.location.href = path;
            context.dispatch(
              "toast",
              {
                message: "Commessa salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            window.location.href = path;
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Add a component/machine to an production
    async addDataToProduction(
      context: ConcessionaireContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any;

      const api = context.rootState.api;

      await fetch(`${api}/productions/${payload.type}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          data = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Commessa modificata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Edit a component/machine of an production
    async editProductionData(
      context: ConcessionaireContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any;

      const api = context.rootState.api;

      await fetch(`${api}/productions/${payload.type}/${payload.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          data = await response.json();
          const res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Commessa modificata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Delete a component/machine from an production
    async deleteProductionData(
      context: ConcessionaireContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/productions/${payload.type}/${payload.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Riga eliminata",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
      return res_status;
    },
    // Get all concessionaire' productions
    async getProductions(context: ConcessionaireContext, data: any) {
      let page = 1;
      let limit = 24;

      const api = context.rootState.api;

      if (data.p) {
        page = data.p;
      }

      if (data.limit) {
        limit = data.limit;
      }

      const offset = (page - 1) * limit;

      const url = `${api}/productions/?limit=${limit}&offset=${offset}`;

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveProductionsList", result);
      });
    },
    async filterProductions(context: ConcessionaireContext, filters: any) {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/productions/filter/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...filters }),
      })
        .then(async (response) => {
          const result = await response.json();
          if (response.status == 200) {
            context.commit("saveProductionsList", result);
          } else {
            await context.dispatch(
              "toast",
              { details: result, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
  },
};

export default concessionaires;
