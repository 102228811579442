

















































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("orders", ["ordersList", "analysis"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("orders/filterOrders", {
      year: new Date().getFullYear(),
    });
    this.$store.dispatch("orders/getAnalysis");
  },
  methods: {
    calcBoxHeight(orders: number): number {
      if (orders > 1000) return 100;

      return orders / 10;
    },
    calcThreshold(orders: any): number {
      const sum = orders
        .map((x: any) => x.orders)
        .reduce((previous: number, current: number) => previous + current, 0);

      const value = sum / orders.length / 10;
      if (value > 100) return 100;
      return value;
    },
  },
})
export default class Analysis extends Vue {
  orders?: any;
  count?: number;
  inLoading!: boolean;
}
