
















































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      isOffice: true,
    };
  },
  computed: {
    ...mapGetters("suppliers", ["suppliersList", "count"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  async mounted() {
    (
      this as Vue & {
        updateSupplierTable: () => void;
      }
    ).updateSupplierTable();
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
          for (let i of value.roles) {
            if (i.description == 'Segreteria') {
              this.$data.isOffice = true
            }
          }
        }
      }
    },
  },
  methods: {
    handleAction() {
      window.location.href = "/fornitori/nuovo";
    },
    async updateSupplierTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("suppliers/getSuppliers", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("suppliers/getSuppliers", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
  },
})
export default class Suppliers extends Vue {
  suppliers?: any;
  count?: number;
  inLoading!: boolean;
}
