





























import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      machines: [],
    };
  },
  computed: {
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("machines/getMachines");
  },
  methods: {
    saveMachine(machine) {
      this.$store.dispatch("machines/editMachine", { ...machine }).then(() => {
        this.$store.dispatch("machines/getMachines");
      });
    },
  },
})
export default class SettingsAssistance extends Vue { }
