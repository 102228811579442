import { RouteConfig } from "vue-router";

import StoreRequirements from "@/views/production/StoreRequirements.vue";
import Productions from "@/views/production/Production.vue";
import Secretariat_order from "@/views/Secretariat.vue";

import denyConcessionaires from "./denyConcessionaires";

const routes: Array<RouteConfig> = [
  {
    path: "/fabbisogno",
    name: "production-store-requirements",
    component: StoreRequirements,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/produzione",
    name: "production",
    component: Productions,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/commessa",
    name: "secretariat_order",
    component: Secretariat_order,
    beforeEnter: denyConcessionaires,
  },
];

export default routes;
