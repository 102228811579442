import { RouteConfig } from "vue-router";

import DashboardConcessionaire from "@/views/concessionaires/Dashboard.vue";
import ConcessionaireOrders from "@/views/concessionaires/Orders.vue";
import ConcessionaireOrdersEdit from "@/views/concessionaires/OrdersEdit.vue";
import ConcessionaireAssistances from "@/views/concessionaires/Assistances.vue";
import ConcessionaireCatalogs from "@/views/concessionaires/Catalogs/Catalogs.vue";
import ConcessionaireCatalogsRequest from "@/views/concessionaires/Catalogs/CatalogsRequest.vue";
import ConcessionaireRequests from "@/views/concessionaires/Catalogs/Request.vue";

import loginBeforeEnter from "./loginBeforeEnter";

const routes: Array<RouteConfig> = [
  {
    path: "/concessionaria/app",
    name: "dashboard-concessionaria",
    component: DashboardConcessionaire,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/concessionaria/commessa",
    name: "concessionaires-orders",
    component: ConcessionaireOrders,
    beforeEnter: loginBeforeEnter,
    props: true,
  },
  {
    path: "/concessionaria/commessa/:id",
    name: "concessionaires-orders-edit",
    component: ConcessionaireOrdersEdit,
    beforeEnter: loginBeforeEnter,
    props: true,
  },
  {
    path: "/concessionaria/assistenza",
    name: "concessionaires-assistances-list",
    component: ConcessionaireAssistances,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/concessionaria/catalogo",
    name: "concessionaires-catalogs",
    component: ConcessionaireCatalogs,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/concessionaria/catalogo/richiesta",
    name: "concessionaires-catalogs",
    component: ConcessionaireRequests,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/concessionaria/catalogo/richiesta/:id",
    name: "concessionaires-catalogs-request",
    component: ConcessionaireCatalogsRequest,
    beforeEnter: loginBeforeEnter,
    props: true,
  },
  {
    path: "/concessionaria/catalogo/richiesta/nuovo/:id",
    name: "concessionaires-catalogs-request",
    component: ConcessionaireCatalogsRequest,
    beforeEnter: loginBeforeEnter,
    props: true,
  }
];

export default routes;
