import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ProductState {
  products: any;
  count: number;
}

type ProductContext = ActionContext<ProductState, RootState>;

const products = {
  namespaced: true,
  state: {
    products: [],
    count: 0,
  },
  getters: {
    productsList: (state: ProductState): any => {
      return state.products;
    },
    count: (state: ProductState): number => {
      return state.count;
    },
  },
  mutations: {
    saveProductsList: (state: ProductState, list: any): void => {
      state.products = list;
    },
    saveProductsCount: (state: ProductState, count: number): void => {
      state.count = count;
    },
  },
  actions: {
    // Get all products
    async getProducts(context: ProductContext): Promise<any> {
      const api = context.rootState.api;

      let result: any;
      await fetch(`${api}/products/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveProductsList", result.results);
        context.commit("saveProductsCount", result.count);
      });

      return result.results;
    },
    // Get all products
    async getProductsRequirements(context: ProductContext): Promise<any> {
      const api = context.rootState.api;

      let result: any;
      await fetch(`${api}/productions/needs/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveProductsList", result.results);
        context.commit("saveProductsCount", result.count);
      });

      return result.results;
    },
    // Get products filtering their supplier
    async filterProducts(context: ProductContext, cod: string): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];

      await fetch(`${api}/products/filter/?limit=1000`, {
        method: "POST",
        body: JSON.stringify({ cod }),
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        result = await response.json();
        result = result.results;
      });

      return result;
    },
    // Get table filter in StoreRequirements
    async filterStore(context: ProductContext, date: any): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];

      await fetch(`${api}/productions/needs/?limit=1000`, {
        method: "POST",
        body: JSON.stringify({ ...date }),
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        result = await response.json();
        result = result.results;
      });

      return result;
    },
    // Create a product
    async createProduct(
      context: ProductContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/products/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Prodotto salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
      return res_status;
    },
    // Edit a product
    async editProduct(context: ProductContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/products/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Prodotto salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default products;
