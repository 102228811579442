

































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import moment from "moment";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    var self = this
    return {
      form: {
        date_from: '',
        date_to: ''
      },
      pageNum: 1,
      currentPage: 1,
      pickerOptionTo: {
        disabledDate(time: any) {
          if (self.$data.form.date_from !== "" || self.$data.form.date_from !== null) {
            return time.getTime() < new Date(self.$data.form.date_from).getTime();
          } else {
            return false;
          }
        }
      },
      pickerOptionFrom: {
        disabledDate(time: any) {
          if (self.$data.form.date_to !== "") {
            return time.getTime() > new Date(self.$data.form.date_to).getTime();
          } else {
            return false;
          }
        }
      }
    };
  },
  watch: {
    form: {
      handler: function (value) {
        if (value.date_from === null || value.date_from === "") {
          this.$data.form.date_from = ""
        } else {
          this.$data.form.date_from = moment(this.$data.form.date_from).startOf('month').format('YYYY-MM-DD hh:mm');
        }

        if (value.date_to === null || value.date_to === "") {
          this.$data.form.date_to = ""
        } else {
          this.$data.form.date_to = moment(this.$data.form.date_to).endOf('month').format('YYYY-MM-DD hh:mm');
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("productions", ["productionsList"]),
    ...mapGetters(["inLoading"]),
  },
  methods: {
    async filterTable() {
      let f = { ...this.$data.form }
      if (f.date_from !== '' && f.date_to !== '' && f.date_from !== null && f.date_to !== null) {
        this.$store.dispatch("productions/filterProductions", f)
      }
    },
  },
})
export default class StoreRequirements extends Vue {
  inLoading!: boolean;
}
