





















































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters, mapActions } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return { order: { machine: { name: "", components: [] } } };
  },
  computed: {
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("orders/findOrder", this.$props.id).then((result) => {
      if (result.status == 404) {
        window.location.href = "/commessa";
      } else {
        this.$data.order = result.data;
      }
    });
  },
  methods: {
    componentsAreLowerbound(components: any): boolean {
      // `.map()` returns an array of booleans
      // `.every()` checks if all elements into the array above are `true`
      return components
        .map((x: any) => x.quantity <= x.component.quantity)
        .every((x: boolean) => x);
    },
  },
})
export default class StoreOrders extends Vue {
  inLoading!: boolean;
}
