






























































































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import moment from "moment";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {
      currentRow: null,
      search: "",
      showOrder: false,
      form: {},
      formPayment: {},
      filterObj: {},
      resetFilters: false,
      date_from: "",
      date_to: "",
      pageNum: 1,
      currentPage: 1,
      options_completed: [
        {
          is_completed: true,
          label: "incassati",
        },
        {
          is_completed: false,
          label: "non incassati",
        },
      ],
      options_assistance: [
        {
          has_assistance: true,
          label: "si",
        },
        {
          has_assistance: false,
          label: "no",
        },
      ],
      rules: {
        payment_method: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
      },
      isOffice: true,
    };
  },
  computed: {
    ...mapGetters("invoices", ["invoicesList", "count"]),
    ...mapGetters("concessionaires", ["productionsList"]),
    ...mapGetters("paymentmethods", ["paymentmethodsList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.isOffice = true
          admin = false
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.isOffice = false
            }
          }
        }
      }
    },
  },
  async mounted() {
    (
      this as Vue & {
        updateInvoicesTable: () => void;
      }
    ).updateInvoicesTable();
    this.$store.dispatch("paymentmethods/getPaymentmethods");
  },
  methods: {
    setCurrent(row: any) {
      (
        this.$refs.productionsTable as Vue & {
          setCurrentRow: (cb: (row: any) => void) => void;
        }
      ).setCurrentRow(row);
    },
    handleCurrentChange(val: any) {
      this.$data.currentRow = val;
    },
    toggleInvoiceFromProduction() {
      this.$data.showOrder = !this.$data.showOrder;
      if (this.$data.showOrder) {
        this.$store.dispatch("concessionaires/getProductions", { limit: 1000 });
      }
      this.$data.currentRow = null;
    },
    getRowsCount(data: any) {
      return data
        .map((x: any) => x.quantity)
        .reduce((a: number, b: number) => a + b, 0);
    },
    createInvoiceFromProduction() {
      (
        this.$refs.formPayment as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          this.$store
            .dispatch("invoices/createInvoice", {
              concessionaire: this.$data.currentRow.concessionaire.id,
              production: this.$data.currentRow.id,
              document_date: moment(),
              reason: "Fattura da commessa",
              payment_method: this.$data.formPayment.payment_method
            })
            .then((status) => {
              if (status == 201) {
                (
                  this as Vue & {
                    updateInvoicesTable: () => void;
                  }
                ).updateInvoicesTable();
                (
                  this as Vue & {
                    toggleInvoiceFromProduction: () => void;
                  }
                ).toggleInvoiceFromProduction();
              }
              this.$data.pageNum =
                parseInt((this.$route.query["p"] as string) ?? null) || 1;
              this.$store.dispatch("invoices/getInvoices", {
                p: this.$data.pageNum,
              });
            });
        }
      });
    },
    async updateInvoicesTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("invoices/getInvoices", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("invoices/getInvoices", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
    editInvoices(invoice: any) {
      this.$store.dispatch("invoices/editInvoices", {
        ...invoice,
        concessionaire: invoice.concessionaire.id,
        production: invoice.production ? invoice.production.id : null,
        assistance: invoice.assistance ? invoice.assistance.id : null,
        payment_method: invoice.payment_method.id,
      });
    },
    // If `isReset` is a valid boolean setted `= true` reset the filter object
    // and get all invoices from the store.
    async filter(isReset?: boolean) {
      if (isReset) {
        this.$data.date_from = "";
        this.$data.date_to = "";
        this.$data.filterObj = {};
        await this.$store.dispatch("invoices/getInvoices", {
          p: this.$data.pageNum,
        });
        this.$data.resetFilters = false;
        return;
      }

      let f = { ...this.$data.filterObj };
      for (let x of Object.keys(f)) {
        if (f[x] == null || f[x] === "") delete f[x];
      }
      if (!(this.$data.date_from == "Invalide date" || !this.$data.date_from)) {
        f.date_from = moment(this.$data.date_from).format("YYYY-MM-DD");
      }
      if (!(this.$data.date_to == "Invalide date" || !this.$data.date_to)) {
        f.date_to = moment(this.$data.date_to).format("YYYY-MM-DD");
      }
      if (Object.entries(f).length !== 0) {
        this.$data.resetFilters = true;
      }
      this.$store.dispatch("invoices/filterInvoice", f);
    },
  },
})
export default class AdministratorInvoces extends Vue {
  invoices?: any;
  count?: number;
  inLoading!: boolean;
}
