












































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      isNew: true,
      form: {
        legal_address: "",
        merchandise_delivery_address: "",
        city: "",
      },
      rules: {
        corporate_name: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        vat: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        payment_method: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        legal_address: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        merchandise_delivery_address: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        rate: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        email: [
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
        email_pec: [
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
      },
      map_key: process.env.VUE_APP_MAPS_KEY,
      addressesAreEquals: false,
    };
  },
  computed: {
    ...mapGetters("profiles", ["profilesList"]),
    ...mapGetters("concessionaires", ["versionsList"]),
    ...mapGetters("paymentmethods", ["paymentmethodsList"]),
    ...mapGetters("rates", ["ratesList"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("concessionaires/findConcessionaire", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/concessionarie";
          } else {
            this.$data.addressesAreEquals =
              result.data.legal_address ==
              result.data.merchandise_delivery_address;
            this.$set(this.$data, "form", result.data);
            this.$data.form.employees = result.data.employees.map(
              (x: any) => x.id
            );
            this.$data.form.machines = result.data.machines.map(
              (x: any) => x.id
            );
            this.$data.form.payment_method = result.data.payment_method.id
            this.$data.form.rate = result.data.rate.id
            this.$store.dispatch("concessionaires/getVersions", result.data.id);
          }
        });
      this.$data.isNew = false;
    }
    this.$store.dispatch("profiles/getProfiles", { limit: 1000 });
    this.$store.dispatch("paymentmethods/getPaymentmethods");
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("rates/getRates");
  },
  methods: {
    sameAddress(event) {
      if (event) {
        this.$set(
          this.$data.form,
          "merchandise_delivery_address",
          this.$data.form.legal_address
        );
      }
      this.$data.addressesAreEquals = event;
    },
    legalAddressMap(place: any) {
      this.$set(this.$data.form, "legal_address", place.formatted_address);
      if (this.$data.addressesAreEquals) {
        this.$set(
          this.$data.form,
          "merchandise_delivery_address",
          place.formatted_address
        );
      }
      this.$data.form.city = place.address_components[3].short_name;
    },
    merchandiseDeliveryAddressMap(place: any) {
      this.$set(
        this.$data.form,
        "merchandise_delivery_address",
        place.formatted_address
      );
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          const website = this.$data.form.website;
          if (website && website != "") {
            if (
              !(website.indexOf("http:") == 0 || website.indexOf("https:") == 0)
            ) {
              this.$data.form.website = "https://" + website;
            }
          }
          if (this.$data.isNew) {
            this.$store
              .dispatch("concessionaires/createConcessionaire", {
                ...this.$data.form,
              })
              .then((id) => {
                if (id > 0) {
                  setTimeout(() => {
                    window.location.href = "/concessionarie/" + id;
                  }, 1000);
                }
              });
          } else {
            this.$store
              .dispatch("concessionaires/editConcessionaire", {
                ...this.$data.form,
              })
              .then(() => {
                this.$store.dispatch(
                  "concessionaires/getVersions",
                  this.$data.form.id
                );
              });
          }
        }
      });
    },
  },
})
export default class ConcessionairesEdit extends Vue {
  concessionaires?: any;
  inLoading!: boolean;
}
