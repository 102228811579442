

























































































































































































































































import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      totalPrice: 0,
      childrenToAlign: [],
      dialogChild: 0,
      search: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        commercial_name: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
      },
      machines: [],
      addMachineRow: false,
      machineProductForm: {},
      pricesForProducts: [],
    };
  },
  computed: {
    ...mapGetters("machines", ["machinesList", "machineCategoriesList"]),
    ...mapGetters("products", ["productsList"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    machinesList: function (machines) {
      (
        this as Vue & {
          listReorder: (machines: any) => void;
        }
      ).listReorder(machines);
    },
    form: {
      handler: function (value) {
        this.$data.totalPrice = 0
        // Prices for every products
        if (value["products"]) {
          for (let product of value["products"]) {
            if (product["id"]) {
              this.$data.totalPrice += Number(product.product.component.price) * product.quantity
              continue;
            }
            const instance = this.$data.pricesForProducts.filter(
              (x: any) => x.id == product.product
            )[0];
            const { price, unit } = instance;
            product.price = Number(price);
            product.unit = unit;
            this.$data.totalPrice += (product.price * product.quantity)
          }
        }

        // Amounts for every machines
        if (value["machines"]) {
          const machines = this.$store.getters["machines/machinesList"].map(
            (x: any) => {
              return { id: x.id, amount: x.amount, unit: x.unit };
            }
          );

          for (let machine of value["machines"]) {
            if (machine.sub_machine["id"]) {
              this.$data.totalPrice += Number(machine.sub_machine.amount) * machine.quantity;
              continue;
            }
            const amount = Number(
              machines.filter((x: any) => x.id == machine.sub_machine)[0].amount
            );
            machine.amount = Number(machine.quantity) * amount;
            this.$data.totalPrice += machine.amount * Number(machine.quantity)
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("machines/getMachineCategories");
    this.$store.dispatch("products/getProducts").then(() => {
      this.$data.pricesForProducts = this.$store.getters[
        "products/productsList"
      ].map((x: any) => {
        return { id: x.id, price: x.component.price, unit: x.component.unit };
      });
    });
  },
  methods: {
    async close() {
      this.$data.form = {}
      this.$data.totalPrice = 0
      return false
    },
    listReorder(machines: any) {
      this.$data.machines = [];

      for (let machine of machines) {
        this.$data.machines.push(machine);
      }
    },
    async searchName() {
      if (this.$data.search.length < 1) {
        this.$store.dispatch("machines/getMachines");
      } else {
        await this.$store
          .dispatch("machines/search", this.$data.search)
          .then((response) => {
            (
              this as Vue & {
                listReorder: (machines: any) => void;
              }
            ).listReorder(response);
          });
      }
    },
    saveNewRow(form: any, ignoreAdd?: boolean) {
      let f: any;
      if (form) {
        f = form;
      } else {
        f = this.$data.machineProductForm;
        f.type = this.$data.addMachineRow ? "machines" : "products";
        if (!(f.quantity && (f.product || f.sub_machine))) {
          this.$store.dispatch("toast", {
            message: "Non hai selezionato tutti l'oggetto o la quantità",
            type: "error",
          });
          return;
        }
        f.machine = this.$data.form.id;
      }
      if (!f.machine) {
        this.$data.form[f.type].push({ ...f });
        this.$data.machineProductForm = {};
      } else {
        this.$store
          .dispatch("machines/addProductOrMachine", {
            ...f,
          })
          .then((response) => {
            if (response.status == 201 && !ignoreAdd) {
              let data = response.data;
              if (f.type == "products") {
                data.product.price *= Number(f.quantity);
              } else {
                data.sub_machine.amount *= Number(f.quantity);
              }
              this.$data.form[f.type].push(data);
              this.$data.machineProductForm = {};
            }
          });
      }
    },
    deleteRow(type: string, id: number, index: number) {
      if (!id) {
        this.$data.form[type].splice(index, 1);
      } else {
        this.$store
          .dispatch("machines/removeProductOrMachine", {
            type,
            id,
          })
          .then((status) => {
            if (status == 204) {
              this.$data.form[type].splice(index, 1);
              this.$store.dispatch("machines/getMachines");
            }
          });
      }
    },
    saveRow(type: string, form: any) {
      const f = { ...form };

      if (f["product"]) {
        if (f.product["id"]) f.product = f.product.id;
      } else if (f["sub_machine"]) {
        if (f.sub_machine["id"]) f.sub_machine = f.sub_machine.id;
      }

      this.$store.dispatch("machines/saveProductOrMachine", {
        type,
        form: f,
      });
      this.$store.dispatch("machines/getMachines");
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate(async (valid: boolean) => {
        if (valid) {
          const f = this.$data.form;

          if (typeof f.category === "string" && f.category != "") {
            await this.$store
              .dispatch("machines/createMachineCategory", {
                label: f.category,
                is_active: true,
              })
              .then(async (response) => {
                if (response.status == 201) {
                  f.category = response.data.id;
                  this.$store.dispatch("machines/getMachineCategories");
                } else {
                  delete f.category;
                }
              });
          }

          if (f.parent == "") f.parent = null;
          let action = "machines/editMachine";

          for (const field of f.products) {
            if (field.product == null) {
              this.$store.dispatch("toast", {
                message: "Non hai selezionato tutti i prodotti",
                type: "error",
              });
              return;
            }
          }

          if (!f.id) {
            action = "machines/createMachine";
          }

          await this.$store
            .dispatch(action, {
              ...f,
            })
            .then(async (response) => {
              if (response.status == 201 || response.status == 200) {
                if (response.status == 201) {
                  for (const product of this.$data.form.products) {
                    product.machine = response.data.id;
                  }
                  for (const machine of this.$data.form.machines) {
                    machine.machine = response.data.id;
                  }
                }

                if (
                  this.$data.form.children &&
                  this.$data.form.children.length > 0
                ) {
                  this.$data.dialogChild = f.id;
                }
              }
            });
          if (!f.id) {
            for (const product of this.$data.form.products) {
              await (
                this as Vue & {
                  saveNewRow: (form: any, ignoreAdd?: boolean) => void;
                }
              ).saveNewRow({ ...product }, true);
            }
            for (const machine of this.$data.form.machines) {
              await (
                this as Vue & {
                  saveNewRow: (form: any, ignoreAdd?: boolean) => void;
                }
              ).saveNewRow({ ...machine }, true);
            }

            // Make another request just to be sure of alignment
            setTimeout(() => {
              this.$store.dispatch("machines/getMachines");
            }, 1000);
          }
          this.$store.dispatch("machines/getMachines");
        }
      });
    },
    async editChild() {
      if (this.$data.childrenToAlign.length > 0) {
        await this.$store.dispatch("machines/childAlignment", {
          children: this.$data.childrenToAlign,
          id: this.$data.dialogChild,
        });
        this.$store.dispatch("machines/getMachines");
      }
    },
    resetChildDialog() {
      this.$data.childrenToAlign = [];
      this.$data.dialogChild = 0;
    },
    editMachine(machine: any) {
      let payload = { ...machine };

      if (payload.category) {
        payload.category = machine.category.id;
      }

      this.$store.dispatch("machines/editMachine", payload).then((response) => {
        if (response.status == 200)
          this.$store.dispatch("machines/getMachines");
      });
    },
    confMachine(machine: any) {
      this.$data.form = { ...machine };
      if (machine.category) {
        this.$data.form.category = machine.category.id;
      }
    },
    async cloneMachine(machine: any) {
      let c = machine.category;
      if (c == null) c = "null";

      await this.$store
        .dispatch("machines/createMachine", {
          ...machine,
          category:
            typeof c == "string" ? machine.category : machine.category.id,
        })
        .then((status) => {
          if (status == 201 || status == 200) {
            this.$store.dispatch("machines/getMachines");
          } else {
            this.$store.dispatch("toast", {
              message: "Errore nella clonazione",
              type: "error",
            });
            return;
          }
        });
    },

    newMachine() {
      this.$data.form = { id: null, products: [], machines: [] };
    },
  },
})
export default class SettingsMachines extends Vue { }
