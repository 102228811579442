







































import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      profiles: [],
      profilesBackup: [],
      isSecretariat: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("profiles", ["rolesList"]),
    ...mapGetters("concessionaires", ["concessionairesList"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    me(value) {
      let checkSecretariat = 0,
        continueControl = true;
      if (value.roles.length == 1 && value.roles[0].description == "Admin") {
        this.$data.isSecretariat = false;
        continueControl = false;
      }
      if (continueControl) {
        for (let i of value.roles) {
          if (i.description == "Admin" || i.description == "Segreteria") {
            checkSecretariat++;
          }
        }
        if (checkSecretariat != 2) {
          this.$data.isSecretariat = true;
        }
      }
    },
  },
  created() {
    this.$store
      .dispatch("profiles/getProfiles", { limit: 1000 })
      .then((result) => {
        let checkConcessionaire = 0;
        let totalResult = 0;
        totalResult = result.length;
        for (let profile of result) {
          const roles = profile.roles.map((x: any) => x.id);
          let employee_of = null;
          if (profile.employee_of) {
            employee_of = profile.employee_of.id;
            checkConcessionaire++;
          }
          this.$data.profiles.push({
            ...profile,
            employee_of,
            roles,
          });
        }
        this.$data.profilesBackup = JSON.parse(
          JSON.stringify(this.$data.profiles)
        );
        if (checkConcessionaire != totalResult) {
          this.$store.dispatch("profiles/getRoles");
        }
      });
    this.$store.dispatch("concessionaires/getConcessionaires", { limit: 1000 });
  },
  methods: {
    async statusAccount(id: number, is_active: any) {
      this.$store.dispatch("profiles/statusUser", { id, is_active });
      this.$store.dispatch("profiles/getProfiles", { limit: 1000 });
    },
    save() {
      for (const i in this.$data.profiles) {
        if (
          JSON.stringify(this.$data.profiles[i]) !=
          JSON.stringify(this.$data.profilesBackup[i])
        ) {
          let fields: any = {
            id: this.$data.profiles[i].id,
            roles: this.$data.profiles[i].roles,
          };
          let checkConcessionaire = true;
          if (this.$data.profiles[i].employee_of) {
            fields["employee_of"] = this.$data.profiles[i].employee_of;
          }
          for (let i = 0; i < fields["roles"].length; i++) {
            if (fields["roles"][i] == 6) {
              if (fields["roles"].length != 1) {
                checkConcessionaire = false;
              }
              break;
            }
          }
          for (let i = 0; i < fields["roles"].length; i++) {
            if (fields["roles"][i] == 1) {
              fields["roles"] = [1, 2, 3, 4, 5];
              break;
            }
          }
          if (checkConcessionaire) {
            if (fields["roles"][0] == 6) {
              if (fields["employee_of"] === undefined) {
                this.$store.dispatch("toast", {
                  message: "Inserire una concessionaria",
                  type: "error",
                });
                break;
              }
              this.$store.dispatch("profiles/editProfile", fields);
            } else {
              this.$store.dispatch("profiles/editProfile", fields);
            }
          } else {
            this.$store.dispatch("toast", {
              message: "Non puoi inserire un concessionario e un secondo ruolo",
              type: "error",
            });
          }
        }
      }
      this.$data.profilesBackup = JSON.parse(
        JSON.stringify(this.$data.profiles)
      );
    },
  },
})
export default class SettingsUsers extends Vue {}
