


































































































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters, mapActions } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      isNew: true,
      form: {
        contacts: [],
      },
      productForm: {},
      contactForm: {},
      tab: "info",
      rules: {
        corporate_name: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        vat: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        legal_address: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        rate: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        merchandise_delivery_address: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        email: [
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
        email_pec: [
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
        nationality: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        delivery_time_num: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        delivery_time_type: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        cod: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
      },
      productRules: {
        name: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
      },
      map_key: process.env.VUE_APP_MAPS_KEY,
      myProducts: [],
      productEditBox: 0,
      addressesAreEquals: false,
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("suppliers", ["versionsList"]),
    ...mapGetters("components", ["componentsList", "categoriesList"]),
    ...mapGetters("rates", ["ratesList"]),
    ...mapGetters("contacts", ["contactsList"]),
  },
  created() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("suppliers/findSupplier", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/fornitori";
          } else {
            this.$data.addressesAreEquals =
              result.data.legal_address ==
              result.data.merchandise_delivery_address;
            this.$set(this.$data, "form", result.data);
            this.$data.form.rate = result.data.rate.id

            this.$store.dispatch("suppliers/getVersions", result.data.id);
            (
              this as Vue & {
                getProducts: (cod: string) => void;
              }
            ).getProducts(result.data.cod);
          }
        });
      this.$data.isNew = false;
    }
    this.$store.dispatch("components/getComponents");
    this.$store.dispatch("rates/getRates");
    this.$store.dispatch("contacts/getContacts");
  },
  methods: {
    sameAddress(event) {
      if (event) {
        this.$set(
          this.$data.form,
          "merchandise_delivery_address",
          this.$data.form.legal_address
        );
      }
      this.$data.addressesAreEquals = event;
    },
    newContact() {
      const body = this.$data.contactForm;
      if (this.$data.isNew) {
        this.$data.form.contacts.push({ ...body });
      } else {
        this.$store
          .dispatch("contacts/addDataToContact", {
            ...body,
            supplier: this.$props.id,
          })
          .then((data) => {
            if (!data.detail) {
              this.$data.form.contacts.push(data);
            }
          });
      }
      this.$data.contactForm = {};
    },
    saveContact(contact) {
      this.$store.dispatch("contacts/editContactData", {
        ...contact,
      });
    },
    deleteContact(id) {
      this.$store
        .dispatch("contacts/deleteContactData", {
          id,
        })
        .then((status) => {
          if (status == 204) {
            for (let i = 0; i < this.$data.form.contacts.length; ++i) {
              if (this.$data.form.contacts[i].id == id) {
                this.$data.form.contacts.splice(i, 1);
                break;
              }
            }
          }
        });
    },
    editProductBox(product: any) {
      this.$data.productForm = {
        ...product,
        component: product.component.id,
      };
      this.$data.productEditBox = product.id;
    },
    legalAddressMap(place: any) {
      this.$set(this.$data.form, "legal_address", place.formatted_address);
      if (this.$data.addressesAreEquals) {
        this.$set(
          this.$data.form,
          "merchandise_delivery_address",
          place.formatted_address
        );
      }
      this.$data.form.city = place.address_components[3].short_name;
    },
    merchandiseDeliveryAddressMap(place: any) {
      this.$set(
        this.$data.form,
        "merchandise_delivery_address",
        place.formatted_address
      );
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          const website = this.$data.form.website;
          if (website && website != "") {
            if (
              !(website.indexOf("http:") == 0 || website.indexOf("https:") == 0)
            ) {
              this.$data.form.website = "https://" + website;
            }
          }
          if (this.$data.isNew) {
            this.$store
              .dispatch("suppliers/createSupplier", {
                ...this.$data.form,
              })
              .then(async (id) => {
                if (id) {
                  for (const contact of this.$data.form.contacts) {
                    await this.$store.dispatch(
                      "contacts/addDataToContact",
                      {
                        supplier: id,
                        ...contact,
                      }
                    );
                  }
                  setTimeout(() => {
                    window.location.href = "/fornitori/" + id;
                  }, 1000);
                }
              });
          } else {
            this.$store
              .dispatch("suppliers/editSupplier", {
                ...this.$data.form,
              })
              .then(() => {
                this.$store.dispatch(
                  "suppliers/getVersions",
                  this.$data.form.id
                );
              });
          }
        }
      });
    },
    saveProduct() {
      (
        this.$refs.productForm as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid && !this.$data.isNew) {
          if (this.$data.productEditBox) {
            this.$store
              .dispatch("products/editProduct", {
                ...this.$data.productForm,
                supplier: this.$data.form.id,
              })
              .then((status: number) => {
                if (status == 200) {
                  (
                    this as Vue & {
                      getProducts: (cod: string) => void;
                    }
                  ).getProducts(this.$data.form.cod);
                  (
                    this as Vue & {
                      undoEditProduct: () => void;
                    }
                  ).undoEditProduct();
                }
              });
          } else {
            this.$store
              .dispatch("products/createProduct", {
                supplier: this.$data.form.id,
                ...this.$data.productForm,
              })
              .then((status: number) => {
                if (status == 201) {
                  (
                    this as Vue & {
                      getProducts: (cod: string) => void;
                    }
                  ).getProducts(this.$data.form.cod);
                  this.$data.productForm = {};
                }
              });
          }
        }
      });
    },
    archiveProduct(id: number) {
      this.$store
        .dispatch("products/editProduct", { id: id, is_active: false })
        .then(() => {
          (
            this as Vue & {
              getProducts: (cod: string) => void;
            }
          ).getProducts(this.$data.form.cod);
        });
    },
    unarchiveProduct(id: number) {
      this.$store
        .dispatch("products/editProduct", { id: id, is_active: true })
        .then(() => {
          (
            this as Vue & {
              getProducts: (cod: string) => void;
            }
          ).getProducts(this.$data.form.cod);
        });
    },
  },
})
export default class SuppliersEdit extends Vue {
  suppliers?: any;
  inLoading!: boolean;

  async getProducts(cod: string) {
    this.$data.myProducts = await this.$store.dispatch(
      "products/filterProducts",
      cod
    );
  }

  undoEditProduct() {
    this.$data.productForm = {};
    this.$data.productEditBox = 0;
  }
}
