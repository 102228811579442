









import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";

@Component({
  components: {
    "t-header": Header,
  },
})
export default class Settings extends Vue { }
