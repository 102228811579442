import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface CatalogsState {
  catalogs: any;
  count: number;
  request: any;
}

type CatalogsContext = ActionContext<CatalogsState, RootState>;

const catalogs = {
  namespaced: true,
  state: {
    catalogs: [],
    request: [],
    count: 0,
  },
  getters: {
    catalogsList: (state: CatalogsState): any => {
      return state.catalogs;
    },
    requestList: (state: CatalogsState): any => {
      return state.request;
    },
    count: (state: CatalogsState): number => {
      return state.count;
    },
  },
  mutations: {
    saveCatalogsList: (state: CatalogsState, list: any): void => {
      state.catalogs = list;
    },
    saveCatalogsCount: (state: CatalogsState, count: number): void => {
      state.count = count;
    },
    saveRequestList: (state: CatalogsState, list: any): void => {
      state.request = list;
    },
  },
  actions: {
    // Get all request
    async getRequest(context: CatalogsContext, data: any): Promise<any> {
      const api = context.rootState.api;
      const url = `${api}/catalogs/requests/?limit=1000`;

      let result: any = {};

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveRequestList", result.results);
        context.commit("saveCatalogsCount", result.count);
      });

      return result;
    },
    // Get all catalogs
    async getCatalog(context: CatalogsContext, data: any): Promise<any> {
      const api = context.rootState.api;
      const url = `${api}/catalogs/?limit=1000`;

      let result: any = {};

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveCatalogsList", result.results);
        context.commit("saveCatalogsCount", result.count);
      });

      return result;
    },
    // Create a Catalog
    async createCatalogs(
      context: CatalogsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;
          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Catalogo salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            context.dispatch(
              "toast",
              {
                message: "Errore nel salvataggio del catalogo",
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Add components linked to a Catalogs
    async addCatalogsComponent(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/components/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 201) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Add machines linked to a Catalogs
    async addCatalogsMachines(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/machines/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 201) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Find a catalog and return it if found. By its `id`
    async findCatalog(
      context: CatalogsContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/catalogs/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Edit a catalog
    async editCatalog(context: CatalogsContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Catalogo salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove component from a catalog
    async removeCatalogComponent(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/requests/components/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Catalogo salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove machine from a catalog
    async removeCatalogMachine(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/machines/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 204) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    /* Remove a catalog
    async deleteCatalog(context: CatalogsContext, id: number): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Catalogo eliminato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },*/
    // Edit component linked to a catalog
    async editCatalogsComponents(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/components/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status != 200) {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit machine linked to a catalog
    async editCatalogsMachines(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/machines/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status != 200) {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Request
    // Requesting orders on catalogs
    // Add component in a request
    async addComponentsRequest(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/requests/components/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 201) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Add machine in a request
    async addMachinesRequest(context: CatalogsContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/requests/machines/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status != 200) {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Create a Request
    async createRequestCatalogs(
      context: CatalogsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/requests/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;
          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Richiesta effettuata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            context.dispatch(
              "toast",
              {
                message: "Errore nella richiesta",
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Edit a request catalog
    async editRequests(
      context: CatalogsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;
      const api = context.rootState.api;
      let id;

      if (payload.request) {
        if (isNaN(payload.request)) {
          id = payload.request;
        } else {
          id = Number(payload.request);
        }
      } else {
        id = payload.id;
      }

      const form = new FormData();
      for (const key of Object.keys(payload)) {
        form.append(key, payload[key]);
      }

      await fetch(`${api}/catalogs/requests/${id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: form,
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Richiesta salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Find a request
    async findRequest(
      context: CatalogsContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/catalogs/requests/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    //Edit a request component
    async editComponentsRequest(
      context: CatalogsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/requests/components/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Modifica effettuata",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    //Remove component for request
    async deleteComponentRequest(
      context: CatalogsContext,
      id: number
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/requests/components/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 204) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    //Edit a request machine
    async editMachinesRequest(
      context: CatalogsContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/catalogs/requests/machines/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Modifica effettuata",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    //Remove machine for request
    async deleteMachineRequest(
      context: CatalogsContext,
      id: number
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/catalogs/requests/machines/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status != 204) {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default catalogs;
