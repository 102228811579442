import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface InvoicesState {
  invoices: any;
  count: any;
  versions: any;
}

type InvoicesContext = ActionContext<InvoicesState, RootState>;

const invoices = {
  namespaced: true,
  state: {
    invoices: [],
    count: 0,
    versions: [],
  },
  getters: {
    invoicesList: (state: InvoicesState): any => {
      return state.invoices;
    },
    versionsList: (state: InvoicesState): any => {
      return state.versions;
    },
    count: (state: InvoicesState): number => {
      return state.count;
    },
    versionList: (state: InvoicesState): any => {
      return state.versions;
    },
  },
  mutations: {
    saveInvoicesList: (state: InvoicesState, list: any): void => {
      state.invoices = list;
    },
    saveInvoicesCount: (state: InvoicesState, count: number): void => {
      state.count = count;
    },
    saveVersions: (state: InvoicesState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // Get all invoices
    async getInvoices(context: InvoicesContext) {
      const api = context.rootState.api;

      await fetch(`${api}/invoices/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveInvoicesList", result.results);
        context.commit("saveInvoicesCount", result.count);
      });
    },
    // Find an invoice and return it if found. By its `id`
    async findInvoice(
      context: InvoicesContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/invoices/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get versions list from a invoices `id`
    async getVersions(context: InvoicesContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/invoices/${id}/versions`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    //Edit a invoices
    async editInvoices(
      context: InvoicesContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/invoices/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;
          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Fattura salvata",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    async filterInvoice(context: InvoicesContext, filters: any) {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/invoices/filter/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...filters }),
      })
        .then(async (response) => {
          const result = await response.json();
          if (response.status == 200) {
            context.commit("saveInvoicesList", result.results);
            context.commit("saveInvoicesCount", result.count);
          } else {
            await context.dispatch(
              "toast",
              { details: result, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Create a invoice
    async createInvoice(context: InvoicesContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let id = 0;
      await fetch(`${api}/invoices/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Fattura salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Add machine or product linked to an invoice
    async addInvoiceMachineOrProduct(context: InvoicesContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/invoices/${payload.type}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Fattura salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove machine or product linked to an invoice
    async removeInvoiceMachineOrProduct(
      context: InvoicesContext,
      payload: any
    ) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/invoices/${payload.type}/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Fattura salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit machine or product linked to an invoice
    async editInvoiceMachineOrProduct(context: InvoicesContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/invoices/${payload.type}/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Fattura salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default invoices;
