


































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("orders", ["ordersList", "count", "statsTable"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    nationality() {
      (
        this as Vue & {
          updateOrderTable: () => void;
        }
      ).updateOrderTable();
    },
  },
  created() {
    (
      this as Vue & {
        updateOrderTable: () => void;
      }
    ).updateOrderTable();
    this.$store.dispatch("orders/getStats", { year: new Date().getFullYear() });
  },
  methods: {
    async updateOrderTable() {
      this.$data.pageNum =
        parseInt((this.$route.query["p"] as string) ?? null) || 1;
      await this.$store.dispatch("orders/getOrders", {
        limit: 30,
        p: this.$data.pageNum,
      });
      setTimeout(() => {
        this.$set(this.$data, "currentPage", this.$data.pageNum);
      }, 1000);
    },
  },
})
export default class Monitoring extends Vue {
  orders?: any;
  count?: number;
  inLoading!: boolean;
}
