

















































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      productions: [],
      fileShowed: "",
    };
  },
  computed: {
    ...mapGetters("concessionaires", ["productionsList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    (
      this as Vue & {
        updateProductionTable: () => void;
      }
    ).updateProductionTable();
  },
  methods: {
    handleAction() {
      window.location.href = "/concessionaria/commessa/nuovo";
    },
    getMachinesIds(machines: any) {
      return machines.map((x: any) => x.cods).join(",");
    },
    getRowsCount(data: any) {
      return data
        .map((x: any) => x.quantity)
        .reduce((a: number, b: number) => a + b, 0);
    },
    async updateProductionTable() {
      this.$data.pageNum =
        parseInt((this.$route.query["p"] as string) ?? null) || 1;

      await this.$store.dispatch("concessionaires/getProductions", {
        p: this.$data.pageNum,
      });

      setTimeout(() => {
        this.$set(this.$data, "currentPage", this.$data.pageNum);
      }, 1000);
    },
    showFile(source: string) {
      this.$data.fileShowed = source;
    },
  },
})
export default class ConcessionaireProductions extends Vue {
  productions?: any;
  inLoading!: boolean;
}
