import { RouteConfig } from "vue-router";

import Settings from "@/views/settings/Settings.vue";
import SettingsAccount from "@/views/settings/Account.vue";
import SettingsUsers from "@/views/settings/Users.vue";
import SettingsCategories from "@/views/settings/Categories.vue";
import SettingsMachines from "@/views/settings/Machines.vue";
import SettingsAssistance from "@/views/settings/Assistance.vue";
import SettingsMachinesCategories from "@/views/settings/MachinesCategories.vue";
import SettingsRegisters from "@/views/settings/Registers.vue";
import SettingsRates from "@/views/settings/Rates.vue";
import SettingsPaymentmethods from "@/views/settings/Paymentmethods.vue";
import SettingStorage from "@/views/settings/Storage.vue";

import loginBeforeEnter from "./loginBeforeEnter";
import authAdmin from "./authAdmin";
import authAdminSecretariat from "./authAdminSecretariat";

const routes: Array<RouteConfig> = [
  {
    path: "/impostazioni",
    name: "settings",
    component: Settings,
    beforeEnter: loginBeforeEnter,
    redirect: "/impostazioni/account",
    children: [
      {
        path: "account",
        name: "settings-account",
        component: SettingsAccount,
      },
      {
        path: "utenti",
        name: "settings-users",
        component: SettingsUsers,
        beforeEnter: authAdminSecretariat, 
      },
      {
        path: "magazzino",
        name: "settings-storage",
        component: SettingStorage,
        beforeEnter: authAdmin,
      },
      {
        path: "categorie",
        name: "settings-categories",
        component: SettingsCategories,
        beforeEnter: authAdmin,
      },
      {
        path: "distinte",
        name: "settings-machines",
        component: SettingsMachines,
        beforeEnter: authAdmin,
      },
      {
        path: "assistenza",
        name: "settings-assistance",
        component: SettingsAssistance,
        beforeEnter: authAdmin,
      },
      {
        path: "categorie-distinte",
        name: "settings-machines-categories",
        component: SettingsMachinesCategories,
        beforeEnter: authAdmin,
      },
      {
        path: "listino",
        name: "settings-registers",
        component: SettingsRegisters,
        beforeEnter: authAdmin,
      },
      {
        path: "aliquote",
        name: "settings-rates",
        component: SettingsRates,
        beforeEnter: authAdmin,
      },
      {
        path: "metodi-di-pagamento",
        name: "settings-payment-methods",
        component: SettingsPaymentmethods,
        beforeEnter: authAdmin,
      },
    ],
  },
];

export default routes;
