<template lang="pug">
  el-table.nohover.history(
    :data="table"
  )
    el-table-column(prop="date" label="Data")
      template(slot-scope="scope")
        span {{ scope.row.revision.date_created | moment('DD/MM/YYYY HH:mm') }}
    el-table-column(prop="owner" label="Utente")
      template(slot-scope="scope")
        span {{ scope.row.revision.user.email }}
    el-table-column(prop="differences" label="Modifica")
      template(slot-scope="scope")
        .diff-version(v-if="scope.row.differences.length > 0")
          span(v-if="pk") {{ scope.row.pk }} : 
          span {{ scope.row.differences[0].field }}:
          s {{ scope.row.differences[0].before }}
          span →
          b {{ scope.row.differences[0].after }}
  </template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "HistoryTable",
  props: ["data", "pk"],
  data() {
    return {
      table: [],
    };
  },
  watch: {
    data: function (value) {
      this.getTable(value);
    },
  },
  methods: {
    getTable(list) {
      this.table = list ?? [];
      this.table = this.table.filter((x) => x.differences.length > 0);
      if (list.length > 0) this.table.unshift(list[0]);
      this.table.reverse();
    },
  },
});
</script>
