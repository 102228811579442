





























import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
  },
  data() {
    return {
      user: {},
    }
  },
  computed: {
    ...mapGetters("auth", ["me"]),
  },
  watch: {
    me(value) {
      this.$data.user = value
    },
  },
  methods: {
    async page(page: string) {
      if (this.$data.user.employee_of !== null) {
        window.location.href = "/concessionaria/" + page
      } else {
        this.$store.dispatch("toast", {
          message: "L'utente non è assocciato alla concessionaria",
          type: "error",
        });
      }
    }
  },
})
export default class DashboardConcessionaire extends Vue {
  me!: any;
}
