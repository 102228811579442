


















































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      viewCatalog: true,
      viewRequest: false,
      isOffice: false,
      machineForm: {},
      componentForm: {},
      viewTable: false,
      catalogList: []
    };
  },
  computed: {
    ...mapGetters("catalogs", ["catalogsList", "requestList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("catalogs/getCatalog").then(() => {
      this.$data.catalogList = this.$store.getters[
        "catalogs/catalogsList"
      ].map((x: any) => {
        if (x.is_active) {
          return{x}
        }
      });
    });
    this.$store.dispatch("catalogs/getRequest");
  },
  methods: {
    async viewGetCatalog(value: any) {
      if (value === "colonne") {
        this.$data.viewCatalog = true;
      } else {
        this.$data.viewCatalog = false;
      }
    },
    viewTableImpiComp(value: any) {
      this.$data.machineForm = value.machines;
      this.$data.componentForm = value.components;
      this.$data.viewTable = true;
    },
    requestModel(value: any) {
      window.location.href = "/concessionaria/catalogo/richiesta/nuovo/"+value.id
    },
  },
})
export default class Catalogs extends Vue {
  catalogs?: any;
}
