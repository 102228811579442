

















import { Component, Vue } from "vue-property-decorator";

import Login from "@/components/Login.vue";

import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("auth", ["isLogged"]),
  },
  components: { login: Login },
})
export default class HomeView extends Vue {
  isLogged!: boolean;

  created() {
    if (this.isLogged) window.location.href = "/app";
  }
}
