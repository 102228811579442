import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface OrderState {
  orders: any;
  count: number;
  versions: any;
  stats: any;
  analysis: any;
  error: any;
  storages: any;
}

type OrderContext = ActionContext<OrderState, RootState>;

const orders = {
  namespaced: true,
  state: {
    orders: [],
    count: 0,
    stats: [],
    analysis: {},
    versions: [],
    error: {},
    storages: [],
  },
  getters: {
    ordersList: (state: OrderState): any => {
      return state.orders;
    },
    count: (state: OrderState): number => {
      return state.count;
    },
    statsTable: (state: OrderState): any => {
      return state.stats;
    },
    analysis: (state: OrderState): any => {
      return state.analysis;
    },
    versionsList: (state: OrderState): any => {
      return state.versions;
    },
    errorList: (state: OrderState): any => {
      return state.error;
    },
    storagesList: (state: OrderState): any => {
      return state.storages;
    },
  },
  mutations: {
    saveOrdersList: (state: OrderState, list: any): void => {
      state.orders = list;
    },
    saveOrdersCount: (state: OrderState, count: number): void => {
      state.count = count;
    },
    saveStats: (state: OrderState, list: any): void => {
      state.stats = list;
    },
    saveAnalysis: (state: OrderState, list: any): void => {
      state.analysis = list;
    },
    saveVersions: (state: OrderState, list: any): void => {
      state.versions = list;
    },
    saveErrorList: (state: OrderState, list: any): void => {
      state.error = list;
    },
    saveStoragesList: (state: OrderState, list: any): void => {
      state.storages = list;
    },
  },
  actions: {
    // Get all orders
    async getOrders(context: OrderContext, data: any): Promise<any> {
      let page = 1;
      let limit = 24;
      const api = context.rootState.api;

      if (data) {
        if (data.p) {
          page = data.p;
        }

        if (data.limit) {
          limit = data.limit;
        }
      }

      const offset = (page - 1) * limit;

      const url = `${api}/orders/?limit=${limit}&offset=${offset}`;

      let result: any = {};

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveOrdersList", result.results);
        context.commit("saveOrdersCount", result.count);
      });

      return result;
    },
    // Get analysis stats
    async getAnalysis(context: OrderContext) {
      context.commit("changeLoadingStatus", true, { root: true });
      const api = context.rootState.api;

      await fetch(`${api}/orders/analysis/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveAnalysis", result);
      });
      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Filter orders
    async filterOrders(context: OrderContext, filters: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      const api = context.rootState.api;
      let page = 1;
      let limit = 24;

      if (filters.p) {
        page = filters.p;
      }

      if (filters.limit) {
        limit = filters.limit;
      }

      const offset = (page - 1) * limit;

      const url = `${api}/orders/filter/?limit=${limit}&offset=${offset}`;

      let result: any = {};

      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...filters }),
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveOrdersList", result.results);
        context.commit("saveOrdersCount", result.count);
      });
      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
    // Get stats
    async getStats(context: OrderContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });
      const api = context.rootState.api;

      const products: any = await context.dispatch(
        "products/getProducts",
        { limit: 10000 },
        { root: true }
      );
      const supplierIds = [...new Set(products.map((x: any) => x.supplier.id))];
      const suppliers: any = [];

      for (const id of supplierIds) {
        suppliers.push({
          id,
          products: products.filter((x: any) => x.supplier.id == id),
        });
      }

      const filters: any = { year: payload.year };
      if (payload.category) filters["category"] = payload.category;
      if (payload.machine) filters["machine"] = payload.machine;
      await fetch(`${api}/orders/stats/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...filters }),
      }).then(async (response) => {
        const result = await response.json();
        for (const supplier of suppliers) {
          supplier["months"] = {};
          for (const month of Object.keys(result)) {
            if (result[month][supplier.id]) {
              supplier["months"][month] = result[month][supplier.id];
            } else {
              supplier["months"][month] = 0;
            }
          }
        }

        context.commit("saveStats", suppliers);
      });
      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Create an order
    async createOrder(context: OrderContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      await fetch(`${api}/orders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;
          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Ordine salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            if (
              data.status === null &&
              data.status_condition === null &&
              data.status_doc === null &&
              data.status_merch === null &&
              data.status_transport === null
            ) {
              await context.dispatch(
                "toast",
                { message: data, type: "error" },
                { root: true }
              );
            } else {
              context.commit("saveErrorList", data);
            }
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Find an order and return it if found. By its `id`
    async findOrder(context: OrderContext, id: number | string): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/orders/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Edit an order
    async editOrder(context: OrderContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/orders/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Ordine salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Upload a document to an order
    async uploadDocument(context: OrderContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      const form = new FormData();
      form.append("order", payload.id);
      form.append("file", payload.file);
      form.append("type", payload.type);
      await fetch(`${api}/orders/documents/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: form,
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Documento caricato",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e.message, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove a document
    async deleteDocument(context: OrderContext, id: number): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/orders/documents/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Documento eliminato",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
      return res_status;
    },
    // Add product linked to an order
    async addOrderProduct(context: OrderContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/orders/products/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Ordine salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit product linked to an order
    async editOrderProduct(context: OrderContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/orders/products/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Ordine salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove product from an order
    async removeOrderProduct(context: OrderContext, payload: any) {
      context.commit("changeLoadingStatus", true, { root: true });

      const api = context.rootState.api;
      let res_status = 500;
      await fetch(`${api}/orders/products/${payload.id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Ordine salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Get versions list from an order `id`
    async getVersions(context: OrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/orders/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Create a Storages
    async createStorage(context: OrderContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/stores/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      }).then(async (response) => {
        const data: any = await response.json();
        res_status = response.status;

        if (res_status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Magazzino salvato con successo",
              type: "success",
            },
            { root: true }
          );
        } else {
          await context.dispatch(
            "toast",
            { message: data, type: "error" },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Get all stores
    async getStorage(context: OrderContext) {
      const api = context.rootState.api;

      await fetch(`${api}/stores/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveStoragesList", result.results);
      });
    },
    // Edit a stores
    async editStorage(context: OrderContext, payload: any): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/stores/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Magazzino salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default orders;
