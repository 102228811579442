import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface SupplierState {
  suppliers: any;
  countSuppliers: number;
  versions: any;
}

type SupplierContext = ActionContext<SupplierState, RootState>;

const suppliers = {
  namespaced: true,
  state: {
    suppliers: [],
    countSuppliers: 0,
    versions: [],
  },
  getters: {
    suppliersList: (state: SupplierState): any => {
      return state.suppliers;
    },
    countSuppliers: (state: SupplierState): number => {
      return state.countSuppliers;
    },
    versionsList: (state: SupplierState): any => {
      return state.versions;
    },
  },
  mutations: {
    saveSuppliersList: (state: SupplierState, list: any): void => {
      state.suppliers = list;
    },
    saveSuppliersCountSuppliers: (state: SupplierState, countSuppliers: number): void => {
      state.countSuppliers = countSuppliers;
    },
    saveVersions: (state: SupplierState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // Get all suppliers
    async getSuppliers(context: SupplierContext, data: any): Promise<any> {
      let page = 1;
      let limit = 24;

      const api = context.rootState.api;

      if (data.p) {
        page = data.p;
      }

      if (data.limit) {
        limit = data.limit;
      }

      const offset = (page - 1) * limit;

      const url = `${api}/suppliers/?limit=${limit}&offset=${offset}`;

      let result: any;
      await fetch(url, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = await response.json();
        context.commit("saveSuppliersList", result.results);
        context.commit("saveSuppliersCountSuppliers", result.count);
      });

      return result.results;
    },
    // Create a supplier
    async createSupplier(
      context: SupplierContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let id = 0;

      const api = context.rootState.api;
      await fetch(`${api}/suppliers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          const res_status = response.status;

          if (res_status == 201) {
            id = data.id;
            context.dispatch(
              "toast",
              {
                message: "Fornitore salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return id;
    },
    // Edit a supplier
    async editSupplier(
      context: SupplierContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/suppliers/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Fornitore salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Find a supplier and return it if found. By its `id`
    async findSupplier(
      context: SupplierContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/suppliers/${id}/`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get versions list from a supplier `id`
    async getVersions(context: SupplierContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/suppliers/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
  },
};

export default suppliers;
