



























































































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";

import { mapGetters } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
  },
  data() {
    return {
      viewTable: false,
      viewArchive: true,
      requests: [],
      listRequests: [],
      machineForm: [],
      componentForm: [],
      isOffice: true,
      viewCatalog: true,
      page: 1,
      form: [],
      columnAvailable: true,
      status: "",
      viewNote: false,
      note: "",
      requestNote: {},
      statusList: [
        {
          statusFilter: "SE",
          label: "Inviato",
        },
        {
          statusFilter: "AC",
          label: "Accettato",
        },
        {
          statusFilter: "CO",
          label: "Concluso",
        },
        {
          statusFilter: "SU",
          label: "Sospeso",
        },
        {
          statusFilter: "AR",
          label: "Archiviato",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("catalogs", ["catalogsList", "requestList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("catalogs/getCatalog");
    this.$store.dispatch("catalogs/getRequest");
  },
  watch: {
    requestList(value: any) {
      this.$data.requests = value;
      this.$data.listRequests = this.$data.requests;
    },
    me(value) {
      let admin = true;
      for (let i of value.roles) {
        if (i.description == "Admin") {
          this.$data.isOffice = true;
          admin = false;
        }
      }
      if (admin) {
        if (value.roles.length >= 1) {
          for (let i of value.roles) {
            if (i.description == "Ufficio Acquisti") {
              this.$data.isOffice = false;
            }
          }
          for (let i of value.roles) {
            if (i.description == "Segreteria") {
              this.$data.isOffice = true;
            }
          }
        }
      }
    },
  },
  methods: {
    addNote(value: any) {
      if (this.$data.viewNote) {
        const payload = { ...this.$data.requestNote };
        payload.catalog = payload.catalog.id;
        payload.note = this.$data.note;
        this.$store.dispatch("catalogs/editRequests", { ...payload });
        setTimeout(() => {
          this.$store.dispatch("catalogs/getRequest");
        }, 1000);
        this.$data.viewNote = false;
      } else {
        this.$data.requestNote = { ...value };
        this.$data.note = value.note;
        this.$data.viewNote = true;
      }
    },
    filterStatus() {
      this.$data.requests = this.$data.listRequests;
      let filter = [];
      if (this.$data.status != "") {
        for (let i in this.$data.requests) {
          if (this.$data.requests[i].status == this.$data.status) {
            filter.push(this.$data.requests[i]);
          }
        }
        this.$data.requests = filter;
      }
    },
    async viewGetArchive(value: any) {
      if (value === "archiviate") {
        this.$data.viewArchive = true;
      } else {
        this.$data.viewArchive = false;
      }
    },
    viewTableImpiComp(value: any) {
      let f_machine = [];
      let f_component = [];
      let machine;
      let component;
      //Machine
      for (let f of value.catalog.machines) {
        machine = {
          availability: f.quantity,
          machine: f.machine.id,
        };
        f_machine.push(machine);
      }
      this.$data.machineForm = value.machines;
      for (let i in this.$data.machineForm) {
        for (let f of f_machine) {
          if (this.$data.machineForm[i].machine.id === f.machine) {
            this.$data.machineForm[i].availability = f.availability;
          }
        }
      }
      //Component
      for (let f of value.catalog.components) {
        component = {
          availability: f.quantity,
          component: f.component.id,
        };
        f_component.push(component);
      }
      this.$data.componentForm = value.components;
      for (let i in this.$data.componentForm) {
        for (let f of f_component) {
          if (this.$data.componentForm[i].component.id === f.component) {
            this.$data.componentForm[i].availability = f.availability;
          }
        }
      }
      this.$data.viewTable = true;
      if (
        value.status == "SU" ||
        value.status == "AC" ||
        value.status == "CO"
      ) {
        this.$data.columnAvailable = false;
      }
    },
    async viewGetCatalog(value: any) {
      if (value === "colonne") {
        this.$data.viewCatalog = true;
      } else {
        this.$data.viewCatalog = false;
      }
    },
    confirmRequest(value: any) {
      let verify = true;
      for (let f of value.catalog.machines) {
        for (let i of value.machines) {
          if (f.quantity < i.quantity) {
            this.$store.dispatch("toast", {
              message: "La quantità del componente/impianto non è disponibile",
              type: "error",
            });
            verify = false;
          }
        }
      }
      if (verify) {
        for (let f of value.catalog.components) {
          for (let i of value.components) {
            if (f.quantity < i.quantity) {
              this.$store.dispatch("toast", {
                message:
                  "La quantità del componente/impianto non è disponibile",
                type: "error",
              });
              verify = false;
            }
          }
        }
      }
      if (verify) {
        const payload = { ...value };
        payload.status = "AC";
        payload.catalog = payload.catalog.id;
        this.$store.dispatch("catalogs/editRequests", { ...payload });
        setTimeout(() => {
          this.$store.dispatch("catalogs/getRequest");
        }, 1000);
      }
    },
    suspendRequest(value: any) {
      const payload = { ...value };
      payload.status = "SU";
      payload.catalog = payload.catalog.id;
      this.$store.dispatch("catalogs/editRequests", { ...payload });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getRequest");
      }, 1000);
    },
    concludedRequest(value: any) {
      const payload = { ...value };
      payload.status = "CO";
      payload.catalog = payload.catalog.id;
      this.$store.dispatch("catalogs/editRequests", { ...payload });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getRequest");
      }, 1000);
    },
    archiveRequest(value: any) {
      const payload = { ...value };
      payload.status = "AR";
      payload.catalog = payload.catalog.id;
      this.$store.dispatch("catalogs/editRequests", { ...payload });
      setTimeout(() => {
        this.$store.dispatch("catalogs/getRequest");
      }, 1000);
    },
  },
})
export default class SecretariatCatalog extends Vue {
  inLoading!: boolean;
}
