import { RouteConfig } from "vue-router";

import Components from "@/views/stores/Components.vue";
import Orders from "@/views/stores/Orders.vue";
import OrderDetails from "@/views/stores/OrderDetails.vue";
import Catalogs from "@/views/stores/Catalogs.vue";
import CatalogsEdit from "@/views/stores/CatalogsEdit.vue";

import denyConcessionaires from "./denyConcessionaires";

const routes: Array<RouteConfig> = [
  {
    path: "/magazzino",
    name: "store-components",
    component: Components,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/commesse",
    name: "store-orders",
    component: Orders,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/commesse/:id",
    name: "store-orders-details",
    component: OrderDetails,
    beforeEnter: denyConcessionaires,
    props: true,
  },
  {
    path: "/magazzino/catalogo",
    name: "store-catalogs",
    component: Catalogs,
    beforeEnter: denyConcessionaires,
  },
  {
    path: "/magazzino/catalogo/:id",
    name: "store-catalogs-edit",
    component: CatalogsEdit,
    beforeEnter: denyConcessionaires,
    props: true,
  },
];

export default routes;
