import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface RegisterState {
  registers: any;
  count: number;
}

type RegisterContext = ActionContext<RegisterState, RootState>;

const registers = {
  namespaced: true,
  state: {
    registers: [],
    count: 0,
  },
  getters: {
    registersList: (state: RegisterState): any => {
      return state.registers;
    },
    count: (state: RegisterState): number => {
      return state.count;
    },
  },
  mutations: {
    saveRegistersList: (state: RegisterState, list: any): void => {
      state.registers = list;
    },
    saveRegistersCount: (state: RegisterState, count: number): void => {
      state.count = count;
    },
  },
  actions: {
    // Get all registers
    async getRegisters(context: RegisterContext) {
      const api = context.rootState.api;

      await fetch(`${api}/registers/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveRegistersList", result.results);
        context.commit("saveRegistersCount", result.count);
      });
    },
    // Create a register
    async createRegister(
      context: RegisterContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 400;

      const api = context.rootState.api;
      await fetch(`${api}/registers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Listino salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit a register
    async editRegister(
      context: RegisterContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/registers/${payload.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Listino salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Remove a register
    async deleteRegister(
      context: RegisterContext,
      id: number
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/registers/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (response) => {
          res_status = response.status;

          if (res_status == 204) {
            context.dispatch(
              "toast",
              {
                message: "Listino eliminato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            const data: any = await response.json();
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default registers;
