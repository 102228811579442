



























































































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      table: [],
      form: {
        is_completed: false,
      },
      rules: {
        concessionaire: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
        payment_method: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
        document_date: [
          {
            required: true,
            message: "Riempi questo campo",
            trigger: "blur",
          },
        ],
      },
      q: "",
      isNew: true,
      editingRow: {
        index: -1,
        id: 0,
        type: "",
      },
      formTable: {},
      formTableNew: {
        machine: null,
        product: null,
        price: null,
        vat_percentage: null,
        quantity: null,
      },
    };
  },
  computed: {
    ...mapGetters("concessionaires", ["concessionairesList"]),
    ...mapGetters("invoices", ["versionsList"]),
    ...mapGetters("rates", ["ratesList"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters("products", ["productsList"]),
    ...mapGetters("paymentmethods", ["paymentmethodsList"]),
    ...mapGetters(["inLoading"]),
  },
  async mounted() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("invoices/findInvoice", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/amministrazione";
          } else {
            (
              this as Vue & {
                getInvoice: (data: any) => void;
              }
            ).getInvoice(result.data);
          }
        });
      this.$data.isNew = false;
    }
    this.$store.dispatch("concessionaires/getConcessionaires", {
      limit: 1000,
    });
    this.$store.dispatch("products/getProducts");
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("rates/getRates");
    this.$store.dispatch("paymentmethods/getPaymentmethods");
  },
  methods: {
    getInvoice(data: any) {
      this.$set(this.$data, "form", data);
      this.$data.form.payment_method = data.payment_method.id;
      this.$data.form.concessionaire = this.$data.form.concessionaire.id;
      if (this.$data.form.production) {
        this.$data.table = this.$data.form.production.machines.concat(
          this.$data.form.production.products
        );
      } else {
        this.$data.table = this.$data.form.machines.concat(
          this.$data.form.products
        );
      }
      this.$store.dispatch("invoices/getVersions", data.id);
    },
    editRow(row: any, index: number) {
      if (this.$data.editingRow.id == row.id) {
        this.$data.editingRow = { index: -1, id: 0, type: "" };
        this.$data.formTable = {};
      } else {
        this.$data.editingRow.index = index;
        this.$data.editingRow.id = row.id;
        this.$data.editingRow.type = row["machine"] ? "machines" : "products";
        this.$data.formTable = { ...row };
        if (this.$data.editingRow.type == "machines") {
          this.$data.formTable["machine"] = row.machine.id;
        } else {
          this.$data.formTable["product"] = row.product.id;
        }
      }
    },
    removeRow(row: any, index?: number) {
      if (this.$data.isNew) {
        this.$data.table.splice(index, 1);
        return;
      }
      this.$store
        .dispatch("invoices/removeInvoiceMachineOrProduct", {
          id: row.id,
          type: row["machine"] ? "machines" : "products",
        })
        .then((status) => {
          if (status == 204) {
            this.$store
              .dispatch("invoices/findInvoice", this.$props.id)
              .then((result) => {
                (
                  this as Vue & {
                    getInvoice: (data: any) => void;
                  }
                ).getInvoice(result.data);
              });
          }
        });
    },
    saveTable(index: number) {
      if (!this.$data.formTable) return;
      if (this.$data.isNew) {
        const f = this.$data.formTable;
        if (f["machine"]) {
          f["machine"] = this.$store.getters["machines/machinesList"].filter(
            (x: any) => x.id == f["machine"]
          )[0];
        }
        if (f["product"]) {
          f["product"] = this.$store.getters["products/productsList"].filter(
            (x: any) => x.id == f["product"]
          )[0];
        }

        f.gross_price = Number(f.price) + (f.price * f.vat_percentage) / 100;
        this.$set(this.$data.table, index, f);

        this.$data.editingRow = { index: -1, id: 0, type: "" };
        this.$data.formTable = {};
        return;
      }

      this.$store
        .dispatch("invoices/editInvoiceMachineOrProduct", {
          ...this.$data.formTable,
          type: this.$data.editingRow.type,
        })
        .then((status) => {
          if (status == 200) {
            this.$data.editingRow = { index: -1, id: 0, type: "" };
            this.$data.formTable = {};
            this.$store
              .dispatch("invoices/findInvoice", this.$props.id)
              .then((result) => {
                (
                  this as Vue & {
                    getInvoice: (data: any) => void;
                  }
                ).getInvoice(result.data);
              });
          }
        });
    },
    addElementToInvoice() {
      if (!this.$data.formTableNew) return;
      if (this.$data.isNew) {
        const f = this.$data.formTableNew;
        if (f["machine"]) {
          f["machine"] = this.$store.getters["machines/machinesList"].filter(
            (x: any) => x.id == f["machine"]
          )[0];
        }
        if (f["product"]) {
          f["product"] = this.$store.getters["products/productsList"].filter(
            (x: any) => x.id == f["product"]
          )[0];
        }

        this.$data.table.push({
          ...f,
          gross_price: Number(f.price) + (f.price * f.vat_percentage) / 100,
          type: this.$data.formTableNew["machine"] ? "machines" : "products",
        });
        this.$data.formTableNew = {
          machine: null,
          product: null,
          price: null,
          vat_percentage: null,
          quantity: null,
        };
      } else {
        this.$store
          .dispatch("invoices/addInvoiceMachineOrProduct", {
            ...this.$data.formTableNew,
            invoice: this.$props.id,
            type: this.$data.formTableNew["machine"] ? "machines" : "products",
          })
          .then((status) => {
            if (status == 201) {
              this.$data.formTableNew = {
                machine: null,
                product: null,
                price: null,
                vat_percentage: null,
                quantity: null,
              };
              this.$store
                .dispatch("invoices/findInvoice", this.$props.id)
                .then((result) => {
                  (
                    this as Vue & {
                      getInvoice: (data: any) => void;
                    }
                  ).getInvoice(result.data);
                });
            }
          });
      }
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          if (this.$data.isNew) {
            this.$store
              .dispatch("invoices/createInvoice", {
                ...this.$data.form,
              })
              .then((id) => {
                if (id > 0) {
                  for (const row of this.$data.table) {
                    if (row['machine']) row.machine = row.machine.id
                    else if (row['product']) row.product = row.product.id
                    this.$store.dispatch(
                      "invoices/addInvoiceMachineOrProduct",
                      {
                        ...row,
                        invoice: id,
                        type: row["machine"] ? "machines" : "products",
                      }
                    );
                  }
                  setTimeout(() => {
                    window.location.href = "/amministrazione/";
                  }, 1000);
                }
              });
          } else {
            this.$store
              .dispatch("invoices/editInvoices", {
                ...this.$data.form,
              })
              .then(() => {
                this.$store.dispatch(
                  "invoices/getVersions",
                  this.$data.form.id
                );
              });
          }
        }
      });
    },
  },
})
export default class InvoicesEdit extends Vue {
  inLoading!: boolean;
}
