








































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      isNew: true,
      form: {
        components: [],
        machines: [],
      },
      components: [],
      machines: [],
      f_component: [],
      f_machine: [],
      id_catalog: "",
      valid: true,
      verifyMachine: true,
      verifyComponent: true,
    };
  },
  computed: {
    ...mapGetters("catalogs", ["catalogsList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    if (this.$route.fullPath.split("/")[4] === "nuovo") {
      this.$store.dispatch("catalogs/getCatalog").then((value: any) => {
        for (let i in value.results) {
          if (value.results[i].id == this.$props.id) {
            this.$data.components = value.results[i].components;
            this.$data.machines = value.results[i].machines;
          }
        }
      });
    } else {
      this.$store
        .dispatch("catalogs/findRequest", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/concessionaria/catalogo/richiesta";
          } else {
            let f_machine = [];
            let f_component = [];
            this.$data.id_catalog = result.data.catalog.id;
            for (let i in result.data.catalog.components) {
              for (let q in result.data.components) {
                if (
                  result.data.catalog.components[i].component.id ===
                  result.data.components[q].component.id
                ) {
                  f_component = result.data.catalog.components[i];
                  delete f_component[1];
                  f_component.orderQuantity = Number(
                    result.data.components[q].quantity
                  );
                  f_component.request = result.data.components[q].request;
                  f_component.active = true;
                  f_component.id = result.data.components[q].id;
                }
              }
              if (f_component.length === 0) {
                this.$data.components.push(result.data.catalog.components[i]);
              } else {
                this.$data.components.push(f_component);
                this.$data.form.components.push(f_component);
              }
              f_component = [];
            }
            for (let i in result.data.catalog.machines) {
              for (let q in result.data.machines) {
                if (
                  result.data.catalog.machines[i].machine.id ===
                  result.data.machines[q].machine.id
                ) {
                  f_machine = result.data.catalog.machines[i];
                  delete f_machine[1];
                  f_machine.orderQuantity = Number(
                    result.data.machines[q].quantity
                  );
                  f_machine.request = result.data.machines[q].request;
                  f_machine.active = true;
                  f_machine.id = result.data.machines[q].id;
                }
              }
              if (f_machine.length === 0) {
                this.$data.machines.push(result.data.catalog.machines[i]);
              } else {
                this.$data.machines.push(f_machine);
                this.$data.form.machines.push(f_machine);
              }
              f_machine = [];
            }
            this.$data.isNew = false;
          }
        });
    }
  },
  methods: {
    removeMachine(value: any) {
      for (let i in this.$data.form.machines) {
        if (this.$data.form.machines[i].machine.id === value.machine.id) {
          Vue.delete(this.$data.form.machines, i);
        }
      }
      for (let i in this.$data.machines) {
        if (this.$data.machines[i].machine.id === value.machine.id) {
          delete this.$data.machines[i].orderQuantity;
        }
      }
      if (value.request) {
        this.$store.dispatch("catalogs/deleteMachineRequest", value.id);
      }
    },
    editMachine(value: any) {
      if (!value.id || this.$data.isNew) {
        let v;
        let f = true;
        if (value.orderQuantity === "0") {
          this.$store.dispatch("toast", {
            message: "La quantità dell'impianto non può essere 0",
            type: "error",
          });
          this.$data.valid = false;
        } else {
          if (value.quantity >= value.orderQuantity) {
            v = {
              orderQuantity: Number(value.orderQuantity),
              machine: value.machine,
            };
            for (let i in this.$data.form.machines) {
              if (this.$data.form.machines[i].machine.id === value.machine.id) {
                this.$data.form.machines[i].orderQuantity = v.orderQuantity;
                f = false;
              }
            }
            if (f) {
              this.$data.form.machines.push(v);
            }
            this.$data.valid = true;
          } else {
            this.$store.dispatch("toast", {
              message:
                "La quantità dell'impianto deve essere minore di quella disponibile",
              type: "error",
            });
            this.$data.valid = false;
          }
        }
      } else {
        let verify = true;
        for (let i in this.$data.form.machines) {
          if (this.$data.form.machines[i].id === value.id) {
            if (value.orderQuantity !== "0" || value.orderQuantity !== 0) {
              if (value.quantity >= value.orderQuantity) {
                this.$data.form.machines[i].orderQuantity = Number(
                  value.orderQuantity
                );
                verify = false;
                this.$data.verifyMachine = true;
              } else {
                this.$store.dispatch("toast", {
                  message:
                    "La quantità dell'impianto deve essere minore di quella disponibile",
                  type: "error",
                });
                this.$data.verifyMachine = false;
                verify = false;
              }
            } else {
              this.$store.dispatch("toast", {
                message: "La quantità dell'impianto non può essere 0",
                type: "error",
              });
              this.$data.verifyMachine = false;
              verify = false;
            }
          }
        }
        if (verify) {
          let v;
          v = {
            active: value.active,
            catalog: Number(value.catalog),
            machine: Number(value.machine.id),
            id: Number(value.id),
            orderQuantity: Number(value.orderQuantity),
            quantity: Number(value.quantity),
            request: Number(this.$props.id),
          };
          this.$data.form.machines.push(v);
          this.$data.verifyMachine = true;
        }
      }
    },
    removeComponent(value: any) {
      for (let i in this.$data.form.components) {
        if (this.$data.form.components[i].component.id === value.component.id) {
          Vue.delete(this.$data.form.components, i);
        }
      }
      for (let i in this.$data.components) {
        if (this.$data.components[i].component.id === value.component.id) {
          delete this.$data.components[i].orderQuantity;
        }
      }
      if (value.request) {
        this.$store.dispatch("catalogs/deleteComponentRequest", value.id);
      }
    },
    editComponent(value: any) {
      if (!value.id || this.$data.isNew) {
        let v;
        let f = true;
        if (value.orderQuantity === "0" || value.orderQuantity === 0) {
          this.$store.dispatch("toast", {
            message: "La quantità del componente non può essere 0",
            type: "error",
          });
          this.$data.valid = false;
        } else {
          if (value.quantity >= value.orderQuantity) {
            v = {
              orderQuantity: Number(value.orderQuantity),
              component: value.component,
            };
            for (let i in this.$data.form.components) {
              if (
                this.$data.form.components[i].component.id ===
                value.component.id
              ) {
                this.$data.form.components[i].orderQuantity = v.orderQuantity;
                f = false;
              }
            }
            if (f) {
              this.$data.form.components.push(v);
            }
            this.$data.valid = true;
          } else {
            this.$store.dispatch("toast", {
              message:
                "La quantità del componente deve essere minore di quella disponibile",
              type: "error",
            });
            this.$data.valid = false;
          }
        }
      } else {
        let verify = true;
        for (let i in this.$data.form.components) {
          if (this.$data.form.components[i].id === value.id) {
            if (value.orderQuantity !== "0" || value.orderQuantity !== 0) {
              if (value.quantity >= value.orderQuantity) {
                this.$data.form.components[i].orderQuantity = Number(
                  value.orderQuantity
                );
                verify = false;
                this.$data.verifyComponent = true;
              } else {
                this.$store.dispatch("toast", {
                  message:
                    "La quantità del componente deve essere minore di quella disponibile",
                  type: "error",
                });
                this.$data.verifyComponent = false;
                verify = false;
              }
            } else {
              this.$store.dispatch("toast", {
                message: "La quantità del componente non può essere 0",
                type: "error",
              });
              this.$data.verifyComponent = false;
              verify = false;
            }
          }
        }
        if (verify) {
          let v;
          v = {
            active: value.active,
            catalog: Number(value.catalog),
            component: Number(value.component.id),
            id: Number(value.id),
            orderQuantity: Number(value.orderQuantity),
            quantity: Number(value.quantity),
            request: Number(this.$props.id),
          };
          this.$data.form.components.push(v);
          this.$data.verifyComponent = true;
        }
      }
    },
    saveRequest() {
      if (
        this.$data.form.machines.length != 0 ||
        this.$data.form.components.length != 0
      ) {
        if (this.$data.isNew) {
          if (this.$data.valid) {
            this.$store
              .dispatch("catalogs/createRequestCatalogs", {
                catalog: this.$props.id,
              })
              .then(async (id) => {
                if (id > 0) {
                  for (const component of this.$data.form.components) {
                    this.$store.dispatch("catalogs/addComponentsRequest", {
                      component: component.component.id,
                      quantity: component.orderQuantity,
                      request: id,
                    });
                  }
                  for (const machine of this.$data.form.machines) {
                    this.$store.dispatch("catalogs/addMachinesRequest", {
                      machine: machine.machine.id,
                      quantity: machine.orderQuantity,
                      request: id,
                    });
                  }
                  setTimeout(() => {
                    window.location.href = "/concessionaria/catalogo";
                  }, 1000);
                }
              });
          } else {
            this.$store.dispatch("toast", {
              message: "Inserire una quantità diversa",
              type: "error",
            });
          }
        } else {
          let verify = true;
          for (const component of this.$data.form.components) {
            if (component.quantity < component.orderQuantity) {
              this.$store.dispatch("toast", {
                message: "Componente non ha la disponibilità richiestà",
                type: "error",
              });
              verify = false;
            } else {
              if (isNaN(component.component)) {
                this.$store.dispatch(
                  "catalogs/deleteComponentRequest",
                  component.id
                );
                component.component = component.component.id;
              }
              component.quantity = component.orderQuantity;
              delete component.orderQuantity;
              this.$store.dispatch("catalogs/editComponentsRequest", {
                ...component,
              });
            }
          }
          for (const machine of this.$data.form.machines) {
            if (machine.quantity < machine.orderQuantity) {
              this.$store.dispatch("toast", {
                message: "Impianto non ha la disponibilità richiestà",
                type: "error",
              });
              verify = false;
            } else {
              if (isNaN(machine.machine)) {
                this.$store.dispatch(
                  "catalogs/deleteMachineRequest",
                  machine.id
                );
                machine.machine = machine.machine.id;
              }
              machine.quantity = machine.orderQuantity;
              delete machine.orderQuantity;
              this.$store.dispatch("catalogs/editMachinesRequest", {
                ...machine,
              });
            }
          }
          if (verify) {
            this.$store.dispatch("catalogs/editRequests", {
              ...this.$data.form,
              request: Number(this.$props.id),
              catalog: Number(this.$data.id_catalog),
            });
            setTimeout(() => {
              window.location.href = "/concessionaria/catalogo/richiesta";
            }, 1000);
          }
        }
      } else {
        this.$store.dispatch("toast", {
          message: "È obbligatorio un componente o impianto nella richiesta",
          type: "error",
        });
      }
    },
  },
})
export default class ConcessionaireCatalogsRequest extends Vue {
  catalogs?: any;
}
