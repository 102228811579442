


























































import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  data() {
    var validatePass2 = (rule: any, value: string, callback: any) => {
      if (value !== this.$data.passwordForm.new_password1) {
        callback(new Error("Le due password non coincidono"));
      } else {
        callback();
      }
    };
    var validatePass = (rule: any, value: any, callback: any) => {
      if (this.$data.passwordForm.new_password1.length < 8) {
        callback(new Error("Password debole"));
        return;
      }
      if (this.$data.passwordForm.new_password1.match(/[A-Z]/) === null) {
        callback(new Error("Inserisci almeno un carattere maiuscolo"));
        return;
      }
      if (this.$data.passwordForm.new_password1.match(/[a-z]/) === null) {
        callback(new Error("Inserisci almeno un carattere minuscolo"));
        return;
      }
      if (this.$data.passwordForm.new_password1.match(/[0-9]/) === null) {
        callback(new Error("Inserisci almeno un valore numerico"));
        return;
      }
      callback();
    };
    return {
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        cellular: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
      },
      passwordForm: {},
      passwordRules: {
        old_password: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        new_password1: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        new_password2: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      isConcessionaire: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  watch: {
    me: function (value) {
      this.$data.form = JSON.parse(JSON.stringify(value));
      this.$data.form.roles = this.$data.form.roles
        .map((x: any) => x.description)
        .join(", ");
      for(let i of value.roles){
        if(i.description == "Concessionario"){
          this.$data.isConcessionaire = true
        }
      }
    },
  },
  methods: {
    handleAvatar(avatar: any) {
      this.$store
        .dispatch("profiles/editAvatar", {
          id: this.$data.form.id,
          avatar: avatar.raw,
        })
        .then((status: number) => {
          if (status == 200) {
            this.$data.form.avatar = URL.createObjectURL(avatar.raw);
          }
        });
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }

        this.$store.dispatch("profiles/editProfile", {
          ...this.$data.form,
          id: "me",
        });
      });
    },
    saveNewPassword() {
      (
        this.$refs.passwordForm as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }

        this.$store.dispatch("auth/changePassword", this.$data.passwordForm);
      });
    },
  },
})
export default class SettingsAccount extends Vue {
  me!: any;
}
