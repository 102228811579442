























































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";

import { mapGetters } from "vuex";

@Component({
  data() {
    return {
      verify_role: false,
      isSecretariat: false,
      rolesStorage: false,
      office_pay: false,
      pageNum: 1,
      currentPage: 1,
    };
  },
  components: {
    "t-header": Header,
  },
  computed: {
    ...mapGetters("concessionaires", ["concessionairesList", "countConcessionaires"]),
    ...mapGetters("suppliers", ["suppliersList", "countSuppliers"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  async mounted() {
    (
      this as Vue & {
        updateConcessionaireTable: () => void;
      }
    ).updateConcessionaireTable();
    (
      this as Vue & {
        updateSupplierTable: () => void;
      }
    ).updateSupplierTable();
  },
  watch: {
    me(value) {
      if (this.$store.getters["auth/isConcessionaire"]) {
        window.location.href = "/concessionaria/app"
      }
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.$data.verify_role = true
        }
      }
      if (!this.$data.verify_role) {
        if (value.roles.length !== 1) {
          for (let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.$data.office_pay = true
            }
          }
          if (!this.$data.office_pay) {
            for (let i of value.roles) {
              if (i.description == 'Segreteria') {
                this.$data.isSecretariat = true
              } else if (i.description == 'Magazziniere' || i.description == 'Capo produzione') {
                this.$data.rolesStorage = true
              }
            }
          }
        } else {
          if (value.roles[0].description == 'Segreteria') {
            this.$data.isSecretariat = true
          } else if (value.roles[0].description == 'Magazziniere' || value.roles[0].description == 'Capo produzione') {
            this.$data.rolesStorage = true
          } else if (value.roles[0].description == 'Ufficio Acquisti') {
            this.$data.office_pay = true
          }
        }
      }
    },
  },
  methods: {
    async updateConcessionaireTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("concessionaires/getConcessionaires", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("concessionaires/getConcessionaires", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
    async updateSupplierTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("suppliers/getSuppliers", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("suppliers/getSuppliers", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
  },
})
export default class Dashboard extends Vue {
  me!: any;
}
