


















































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters, mapActions } from "vuex";

@Component({
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "history-table": HistoryTable,
  },
  data() {
    return {
      pageNum: 1,
      currentPage: 1,
      versionId: 0,
      statuses: {
        PE: "In corso",
        WO: "In corso",
        CO: "Completato",
      },
    };
  },
  computed: {
    ...mapGetters("assistances", ["assistancesList", "count", "versionsList"]),
    ...mapGetters(["inLoading"]),
  },
  async mounted() {
    (
      this as Vue & {
        updateAssistanceTable: () => void;
      }
    ).updateAssistanceTable();
  },
  methods: {
    async updateAssistanceTable() {
      if (this.$route.query["q"]) {
        await this.$store.dispatch("assistances/getAssistances", {
          q: this.$route.query.q,
        });
      } else {
        this.$data.pageNum =
          parseInt((this.$route.query["p"] as string) ?? null) || 1;
        await this.$store.dispatch("assistances/getAssistances", {
          p: this.$data.pageNum,
        });
        setTimeout(() => {
          this.$set(this.$data, "currentPage", this.$data.pageNum);
        }, 1000);
      }
    },
    newAssistance() {
      window.location.href = "/concessionaria/assistenza/nuovo";
    },
    confAssistance(id: number) {
      window.location.href = "/concessionaria/assistenza/" + id;
    },
    getVersions(id: number) {
      this.$store.dispatch("assistances/getVersions", id);
      this.$data.versionId = id;
    },
  },
})
export default class Assistances extends Vue {
  assistances?: any;
  count?: number;
  inLoading!: boolean;
}
