






































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      isNew: true,
      form: {
        request_date: new Date(),
        machines: [],
        products: [],
      },
      machineForm: {},
      productForm: {},
      tab: 0,
      file: null,
      rules: {
        expected_delivery_date: [
          {
            required: true,
            message: "Campo obbligatorio",
          },
        ],
      },
      fileShowed: "",
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters("products", ["productsList"]),
  },
  watch: {
    me(value) {
      if (!value.employee_of) window.location.href = "/concessionaria/app";
    },
  },
  created() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("concessionaires/findProduction", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/concessionaria/commessa";
          } else {
            this.$set(this.$data, "form", result.data);
            this.$data.form.machines = this.$data.form.machines.map(
              (x: any) => {
                return {
                  quantity: x.quantity,
                  machine: x.machine.id,
                  id: x.id,
                };
              }
            );
            this.$data.form.products = this.$data.form.products.map(
              (x: any) => {
                return {
                  quantity: x.quantity,
                  product: x.product.id,
                  id: x.id,
                };
              }
            );
          }
        });
      this.$data.isNew = false;
    }
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("products/getProducts");
  },
  methods: {
    manageFiles(event) {
      this.$data.file = event.target.files[0];
    },
    showFile(source: string) {
      this.$data.fileShowed = source;
    },
    newMachine() {
      const body = this.$data.machineForm;
      if (!(body.machine && body.quantity)) {
        this.$store.dispatch("toast", {
          message: "Seleziona una distinta e una quantità",
          type: "error",
        });
        return;
      }

      if (this.$data.isNew) {
        this.$data.form.machines.push({ ...body });
      } else {
        this.$store
          .dispatch("concessionaires/addDataToProduction", {
            production: this.$props.id,
            ...body,
            type: "machines",
          })
          .then((data) => {
            if (!data.detail) {
              this.$data.form.machines.push(data);
            }
          });
      }

      this.$data.machineForm = {};
    },
    saveMachine(machine) {
      if (!(machine.machine && machine.quantity)) {
        this.$store.dispatch("toast", {
          message: "Seleziona una distinta e una quantità",
          type: "error",
        });
        return;
      }

      this.$store.dispatch("concessionaires/editProductionData", {
        ...machine,
        type: "machines",
      });
    },
    deleteMachine(id) {
      this.$store
        .dispatch("concessionaires/deleteProductionData", {
          id,
          type: "machines",
        })
        .then((status) => {
          if (status == 204) {
            for (let i = 0; i < this.$data.form.machines.length; ++i) {
              if (this.$data.form.machines[i].id == id) {
                this.$data.form.machines.splice(i, 1);
                break;
              }
            }
          }
        });
    },
    newProduct() {
      const body = this.$data.productForm;
      if (!(body.product && body.quantity)) {
        this.$store.dispatch("toast", {
          message: "Seleziona un prodotto e una quantità",
          type: "error",
        });
        return;
      }

      if (this.$data.isNew) {
        this.$data.form.products.push({ ...body });
      } else {
        this.$store
          .dispatch("concessionaires/addDataToProduction", {
            production: this.$props.id,
            type: "products",
            ...body,
          })
          .then((data) => {
            if (!data.detail) {
              this.$data.form.products.push(data);
            }
          });
      }

      this.$data.productForm = {};
    },
    saveProduct(product) {
      if (!(product.product && product.quantity)) {
        this.$store.dispatch("toast", {
          message: "Seleziona un prodotto e una quantità",
          type: "error",
        });
        return;
      }

      this.$store.dispatch("concessionaires/editProductionData", {
        ...product,
        type: "products",
      });
    },
    deleteProduct(id) {
      this.$store
        .dispatch("concessionaires/deleteProductionData", {
          id,
          type: "products",
        })
        .then((status) => {
          if (status == 204) {
            for (let i = 0; i < this.$data.form.products.length; ++i) {
              if (this.$data.form.products[i].id == id) {
                this.$data.form.products.splice(i, 1);
                break;
              }
            }
          }
        });
    },
    save() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (valid) {
          this.$data.form.request_date = moment(
            this.$data.form.request_date
          ).format("YYYY-MM-DD HH:mm");

          if (this.$data.form.expected_delivery_date) {
            this.$data.form.expected_delivery_date = moment(
              this.$data.form.expected_delivery_date
            ).format("YYYY-MM-DD HH:mm");
          }

          if (this.$data.isNew) {
            if (
              this.$data.form.machines.filter(
                (x: any) => !x.machine || !x.quantity
              ).length
            ) {
              this.$store.dispatch("toast", {
                message:
                  "Seleziona una distinta e una quantità per tutte le macchine",
                type: "error",
              });
              return;
            }
            const payload = {
              ...this.$data.form,
              concessionaire: this.$store.getters["auth/me"].employee_of.id,
            };

            if (this.$data.file) {
              payload["confirmation_document"] = this.$data.file;
            }

            this.$store
              .dispatch("concessionaires/createProduction", payload)
              .then(async (id) => {
                if (id) {
                  for (const machine of this.$data.form.machines) {
                    await this.$store.dispatch(
                      "concessionaires/addDataToProduction",
                      {
                        production: id,
                        ...machine,
                        type: "machines",
                      }
                    );
                  }
                  for (const product of this.$data.form.products) {
                    await this.$store.dispatch(
                      "concessionaires/addDataToProduction",
                      {
                        production: id,
                        ...product,
                        type: "products",
                      }
                    );
                  }

                  setTimeout(() => {
                    window.location.href = "/concessionaria/commessa/" + id;
                  }, 1000);
                }
              });
          } else {
            const payload = { ...this.$data.form };
            delete payload.concessionaire;
            if (this.$data.file) {
              payload["confirmation_document"] = this.$data.file;
            } else {
              delete payload.confirmation_document;
            }
            this.$store
              .dispatch("concessionaires/editProduction", payload)
              .then(() => {
                this.$store
                  .dispatch("concessionaires/findProduction", this.$props.id)
                  .then((result) => {
                    if (result.status == 404) {
                      window.location.href = "/concessionaria/commessa";
                    } else {
                      this.$set(this.$data, "form", result.data);
                      this.$set(this.$data, "file", "");
                    }
                  });
              });
          }
        }
      });
    },
  },
})
export default class ConcessionaireProductionsEdit extends Vue {
  inLoading!: boolean;
}
