
























import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  props: ["uid", "token"],
  computed: {
    ...mapGetters(["inLoading"]),
  },
  methods: {
    submitForm() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }

        this.$store.dispatch("auth/passwordResetConfirm", {
          ...this.$data.form,
          ...this.$props,
        });
      });
    },
  },
})
export default class ForgotPassword extends Vue {
  data() {
    var validatePass2 = (rule: any, value: string, callback: any) => {
      if (value !== this.$data.form.new_password1) {
        callback(new Error("Le due password non coincidono"));
      } else {
        callback();
      }
    };
    return {
      form: {
        new_password1: "",
        new_password2: "",
      },
      rules: {
        new_password1: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
        ],
        new_password2: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  }
}
