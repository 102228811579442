

















import { Component, Vue } from "vue-property-decorator";

@Component({
  methods: {
    goback() {
      window.history.back();
    },
  },
})
export default class Page404 extends Vue { }
