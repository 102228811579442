
















































































































































































import { Component, Vue } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import BlueBar from "@/components/BlueBar.vue";
import FileBox from "@/components/FileBox.vue";

import { mapGetters } from "vuex";

@Component({
  props: ["id"],
  components: {
    "t-header": Header,
    "blue-bar": BlueBar,
    "file-box": FileBox,
  },
  data() {
    return {
      isNew: true,
      form: {
        concessionaire: '',
        machines: [],
        components: [],
      },
      machineForm: {},
      componentForm: {},
      rules: {
        concessionaire: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("concessionaires", ["concessionairesList"]),
    ...mapGetters("machines", ["machinesList"]),
    ...mapGetters("components", ["componentsList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters(["inLoading"]),
  },
  created() {
    this.$store.dispatch("machines/getMachines");
    this.$store.dispatch("components/getComponents");
    this.$store.dispatch("concessionaires/getConcessionaires", {
      limit: 1000,
    });
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("catalogs/findCatalog", this.$props.id)
        .then((result) => {
          if (result.status == 404) {
            window.location.href = "/magazzino/catalogo";
          } else {
            (
              this as Vue & {
                getCatalog: (data: any) => void;
              }
            ).getCatalog(result.data);
            this.$data.form.concessionaire = result.data.concessionaire.corporate_name
          }
        });
      this.$data.isNew = false;
    }
  },
  methods: {
    // View all catalog
    getCatalog(data: any) {
      this.$set(this.$data, "form", data);
      this.$data.form.components = this.$data.form.components.map((x: any) => {
        return {
          id: x.id,
          component: x.component.id,
          quantity: x.quantity,
        };
      });
      this.$data.form.machines = this.$data.form.machines.map((x: any) => {
        return {
          id: x.id,
          machine: x.machine.id,
          quantity: x.quantity,
        };
      });
    },
    // Component Method
    saveNewComponents() {
      let verify = true;
      if (
        this.$data.componentForm.quantity === "0" ||
        this.$data.componentForm.quantity === undefined
      ) {
        this.$store.dispatch("toast", {
          message: "La quantità del componente deve essere maggiore di 0",
          type: "error",
        });
        this.$data.componentForm = {};
      } else if (this.$data.componentForm.component === undefined) {
        this.$store.dispatch("toast", {
          message: "Deve essere selezionato un componente",
          type: "error",
        });
        this.$data.componentForm = {};
      } else {
        for (let i in this.$data.form.components) {
          if (
            this.$data.form.components[i].component ===
            this.$data.componentForm.component
          ) {
            this.$data.form.components[i].quantity =
              Number(this.$data.componentForm.quantity) +
              Number(this.$data.form.components[i].quantity);
            if (this.$data.isNew) {
              this.$data.componentForm = {};
            } else {
              this.$store
              .dispatch("catalogs/editCatalogsComponents", {
                ...this.$data.form.components[i],
                catalog: this.$props.id,
              })
              .then((status) => {
                if (status == 200) {
                  this.$store
                    .dispatch("catalogs/findCatalog", this.$props.id)
                    .then((result) => {
                      (
                        this as Vue & {
                          getCatalog: (data: any) => void;
                        }
                      ).getCatalog(result.data);
                    });
                }
              });
            }
            this.$data.componentForm = {};
            verify = false;
          }
        }
        if (verify) {
          if (this.$data.isNew) {
            this.$data.form.components.push({ ...this.$data.componentForm });
            this.$data.componentForm = {};
          } else {
            this.$store
              .dispatch("catalogs/addCatalogsComponent", {
                ...this.$data.componentForm,
                catalog: this.$props.id,
              })
              .then((status) => {
                if (status == 201) {
                  this.$store
                    .dispatch("catalogs/findCatalog", this.$props.id)
                    .then((result) => {
                      (
                        this as Vue & {
                          getCatalog: (data: any) => void;
                        }
                      ).getCatalog(result.data);
                    });
                  this.$data.componentForm = {};
                }
              });
          }
        }
      }
    },
    removeComponent(id: number | undefined, index: number) {
      if (!id) {
        this.$data.form.components.splice(index, 1);
      } else {
        this.$store
          .dispatch("catalogs/removeCatalogComponent", {
            id,
          })
          .then((status) => {
            if (status == 204) {
              this.$store
                .dispatch("catalogs/findCatalog", this.$props.id)
                .then((result) => {
                  (
                    this as Vue & {
                      getCatalog: (data: any) => void;
                    }
                  ).getCatalog(result.data);
                });
            }
          });
      }
    },
    editComponents(row: any) {
      if (!row.id) return;

      this.$store
        .dispatch("catalogs/editCatalogsComponents", {
          ...row,
          catalog: this.$props.id,
        })
        .then((status) => {
          if (status == 200) {
            this.$store
              .dispatch("catalogs/findCatalog", this.$props.id)
              .then((result) => {
                (
                  this as Vue & {
                    getCatalog: (data: any) => void;
                  }
                ).getCatalog(result.data);
              });
          }
        });
    },
    // Machines Method
    saveNewMachine() {
      let verify = true;
      if (
        this.$data.machineForm.quantity === "0" ||
        this.$data.machineForm.quantity === undefined
      ) {
        this.$store.dispatch("toast", {
          message: "La quantità dell'impianto deve essere maggiore di 0",
          type: "error",
        });
        this.$data.machineForm = {};
      } else if (this.$data.machineForm.machine === undefined) {
        this.$store.dispatch("toast", {
          message: "Deve essere selezionato un impianto",
          type: "error",
        });
        this.$data.machineForm = {};
      } else {
        for (let i in this.$data.form.machines) {
          if (
            this.$data.form.machines[i].machine ===
            this.$data.machineForm.machine
          ) {
            this.$data.form.machines[i].quantity =
              Number(this.$data.machineForm.quantity) +
              Number(this.$data.form.machines[i].quantity);
            if (this.$data.isNew) {
              this.$data.machineForm = {};
            } else {
              this.$store
                .dispatch("catalogs/editCatalogsMachines", {
                  ...this.$data.form.machines[i],
                  catalog: this.$props.id,
                })
                .then((status) => {
                  if (status == 200) {
                    this.$store
                      .dispatch("catalogs/findCatalog", this.$props.id)
                      .then((result) => {
                        (
                          this as Vue & {
                            getCatalog: (data: any) => void;
                          }
                        ).getCatalog(result.data);
                      });
                  }
                });
            }
            this.$data.machineForm = {};
            verify = false;
          }
        }
        if (verify) {
          if (this.$data.isNew) {
            this.$data.form.machines.push({ ...this.$data.machineForm });
            this.$data.machineForm = {};
          } else {
            this.$store
              .dispatch("catalogs/addCatalogsMachines", {
                ...this.$data.machineForm,
                catalog: this.$props.id,
              })
              .then((status) => {
                if (status == 201) {
                  this.$store
                    .dispatch("catalogs/findCatalog", this.$props.id)
                    .then((result) => {
                      (
                        this as Vue & {
                          getCatalog: (data: any) => void;
                        }
                      ).getCatalog(result.data);
                    });
                  this.$data.machineForm = {};
                }
              });
          }
        }
      }
    },
    removeMachine(id: number | undefined, index: number) {
      if (!id) {
        this.$data.form.machines.splice(index, 1);
      } else {
        this.$store
          .dispatch("catalogs/removeCatalogMachine", {
            id,
          })
          .then((status) => {
            if (status == 204) {
              this.$store
                .dispatch("catalogs/findCatalog", this.$props.id)
                .then((result) => {
                  (
                    this as Vue & {
                      getCatalog: (data: any) => void;
                    }
                  ).getCatalog(result.data);
                });
            }
          });
      }
    },
    editMachines(row: any) {
      if (!row.id) return;

      this.$store
        .dispatch("catalogs/editCatalogsMachines", {
          ...row,
          catalog: this.$props.id,
        })
        .then((status) => {
          if (status == 200) {
            this.$store
              .dispatch("catalogs/findCatalog", this.$props.id)
              .then((result) => {
                (
                  this as Vue & {
                    getCatalog: (data: any) => void;
                  }
                ).getCatalog(result.data);
              });
          }
        });
    },
    // Save catalog
    save() {
      if (
        this.$data.form.components.length === 0 &&
        this.$data.form.machines.length === 0
      ) {
        this.$store.dispatch("toast", {
          message: "Inserire un componente o un impianto",
          type: "error",
        });
      } else {
        let verify = true;
        for (let comp of this.$data.form.components) {
          if (comp.quantity === "0") {
            verify = false;
            this.$store.dispatch("toast", {
              message: "La quantità del componente non può essere 0",
              type: "error",
            });
          }
        }
        for (let imp of this.$data.form.machines) {
          if (imp.quantity === "0") {
            verify = false;
            this.$store.dispatch("toast", {
              message: "La quantità dell'impianto non può essere 0",
              type: "error",
            });
          }
        }
        if (verify) {
          (
            this.$refs.form as Vue & {
              validate: (cb: (valid: boolean) => void) => void;
            }
          ).validate((valid: boolean) => {
            if (valid) {
              if (this.$data.isNew) {
                this.$store
                  .dispatch("catalogs/createCatalogs", {
                    ...this.$data.form,
                  })
                  .then(async (id) => {
                    if (id > 0) {
                      for (const component of this.$data.form.components) {
                        this.$store.dispatch("catalogs/addCatalogsComponent", {
                          ...component,
                          catalog: id,
                        });
                      }
                      for (const machine of this.$data.form.machines) {
                        this.$store.dispatch("catalogs/addCatalogsMachines", {
                          ...machine,
                          catalog: id,
                        });
                      }
                      setTimeout(() => {
                        window.location.href = "/magazzino/catalogo/";
                      }, 1000);
                    }
                  });
              } else {
                this.$data.form.concessionaire =
                  this.$data.form.concessionaire.id;
                this.$store.dispatch("catalogs/editCatalog", {
                  ...this.$data.form,
                });
                setTimeout(() => {
                  window.location.href = "/magazzino/catalogo/";
                }, 1000);
              }
            }
          });
        }
      }
    },
  },
})
export default class CatalogsEdit extends Vue {
  inLoading!: boolean;
}
