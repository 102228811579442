import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ComponentState {
  components: any;
  count: number;
  versions: any;
  categories: any;
}

type ComponentContext = ActionContext<ComponentState, RootState>;

const components = {
  namespaced: true,
  state: {
    components: [],
    count: 0,
    versions: [],
    categories: [],
  },
  getters: {
    componentsList: (state: ComponentState): any => {
      return state.components;
    },
    categoriesList: (state: ComponentState): any => {
      return state.categories;
    },
    count: (state: ComponentState): number => {
      return state.count;
    },
    versionsList: (state: ComponentState): any => {
      return state.versions;
    },
  },
  mutations: {
    saveComponentsList: (state: ComponentState, list: any): void => {
      state.components = list;
    },
    saveCategoriesList: (state: ComponentState, list: any): void => {
      state.categories = list;
    },
    saveComponentsCount: (state: ComponentState, count: number): void => {
      state.count = count;
    },
    saveVersions: (state: ComponentState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // Get all components
    async getComponents(context: ComponentContext) {
      const api = context.rootState.api;

      await fetch(`${api}/components/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveComponentsList", result.results);
        context.commit("saveComponentsCount", result.count);
      });
    },
    // Create a component
    async createComponent(
      context: ComponentContext,
      payload: any
    ): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any = {
        status: 400,
        data: {},
      };

      const api = context.rootState.api;
      await fetch(`${api}/components/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          data = {
            status: response.status,
            data: await response.json(),
          };

          if (data.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Componente salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Edit a component
    async editComponent(context: ComponentContext, payload: any): Promise<any> {
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any = {
        status: 400,
        data: {},
      };

      const api = context.rootState.api;
      await fetch(`${api}/components/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          data = {
            status: response.status,
            data: await response.json(),
          };

          if (data.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Componente salvato con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data.data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    },
    // Get versions list from a component `id`
    async getVersions(context: ComponentContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/components/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Get all categories
    async getCategories(context: ComponentContext) {
      const api = context.rootState.api;

      await fetch(`${api}/components/categories/?limit=1000`, {
        headers: {
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const result = await response.json();
        context.commit("saveCategoriesList", result.results);
      });
    },
    // Create a category
    async createCategory(
      context: ComponentContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/components/categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Categoria salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
    // Edit a category
    async editCategory(
      context: ComponentContext,
      payload: any
    ): Promise<number> {
      context.commit("changeLoadingStatus", true, { root: true });
      let res_status = 500;

      const api = context.rootState.api;
      await fetch(`${api}/components/categories/${payload.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${context.rootGetters["auth/accessToken"]}`,
        },
        body: JSON.stringify({ ...payload }),
      })
        .then(async (response) => {
          const data: any = await response.json();
          res_status = response.status;

          if (res_status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Categoria salvata con successo",
                type: "success",
              },
              { root: true }
            );
          } else {
            await context.dispatch(
              "toast",
              { message: data, type: "error" },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res_status;
    },
  },
};

export default components;
