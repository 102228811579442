













































































































































































































import Vue from "vue";

import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "Header",
  props: ["activeIndex", "hideSidebar"],
  data() {
    return {
      avatar: "",
      expand: true,
      showLogBox: false,
      page: "",
      user: {},
      verify_role: false,
      isSecretariat: false,
      rolesStorage: false,
      office_pay: false,
      pageStatus: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["me", "isConcessionaire"]),
  },
  watch: {
    me(value) {
      this.$data.user = value
      for (let i of value.roles) {
        if (i.description == 'Admin') {
          this.verify_role = true
        }
      }
      if (!this.verify_role) {
        if (value.roles.length !== 1) {
          for(let i of value.roles) {
            if (i.description == 'Ufficio Acquisti') {
              this.office_pay = true
            }
          }
          if (!this.office_pay) {
            for(let i of value.roles) {
              if (i.description == 'Segreteria') {
                this.isSecretariat = true
              } else if (i.description == 'Magazziniere' || i.description == 'Capo produzione') {
                this.rolesStorage = true
              }
            }
          }
        } else {
          if (value.roles[0].description == 'Segreteria') {
            this.isSecretariat = true
          } else if (value.roles[0].description == 'Magazziniere' || value.roles[0].description == 'Capo produzione') {
            this.rolesStorage = true
          } else if (value.roles[0].description == 'Ufficio Acquisti') {
            this.$data.office_pay = true
          }
        }
      }
    },
  },
  created() {
    this.$data.pageStatus = this.$route.fullPath
  },
  mounted() {
    this.$store.dispatch("auth/findMe").then((status) => {
      if (status == 401) return this.makeLogout();

      const me = this.$store.getters["auth/me"];
      if (me.name) this.avatar = me.name[0].toUpperCase();
      else this.avatar = me.email[0].toUpperCase();
    });

    this.expand = localStorage.getItem("sidebar") == "false" ? false : true;
    this.page = this.$route.name ?? "";
  },
  methods: {
    async pageMenu(pageMenu: string) {
      if (this.$data.user.employee_of !== null) {
        window.location.href = "/concessionaria/" + pageMenu
      } else {
        this.$store.dispatch("toast", {
          message: "L'utente non è assocciato alla concessionaria",
          type: "error",
        });
      }
    },
    toggleSidebar() {
      this.expand = !this.expand;
      localStorage.setItem("sidebar", this.expand.toString());
    },
    makeLogout() {
      this.logout().then(() => {
        window.location.href = "/";
      });
    },
    goback() {
      window.history.back();
    },
    ...mapActions("auth", ["logout"]),
  },
});
