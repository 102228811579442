





















import { Component, Vue } from "vue-property-decorator";

import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["inLoading"]),
  },
  methods: {
    submitForm() {
      (
        this.$refs.form as Vue & {
          validate: (cb: (valid: boolean) => void) => void;
        }
      ).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }

        this.$store.dispatch("auth/passwordReset", this.$data.form.email);
      });
    },
  },
})
export default class ForgotPassword extends Vue {
  data() {
    return {
      form: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur",
          },
          { type: "email", message: "Usa una email valida", trigger: "blur" },
        ],
      },
    };
  }
}
